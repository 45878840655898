import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import './LoginForm.css';
import { logUserAccess } from '../../helpers/api';

function LoginForm(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const {setCurrentUser, refreshAccessToken } = useAuth();
    const [recaptchaToken, setRecaptchaToken] = useState('');
    
    // Function to generate reCAPTCHA token
    const generateRecaptchaToken = async () => {
        if (window.grecaptcha && window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(async () => {
                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: 'login'});
                setRecaptchaToken(token);
            });
        }
    };

    // Generate token on component mount
    useEffect(() => {
        generateRecaptchaToken();
    }, []);

    const handleSubmit = async (event) => {
        setErrorMessage('');
        event.preventDefault();

        // Refresh the reCAPTCHA token
        await generateRecaptchaToken();
    
        // Convert username to lowercase before submitting
        const lowerCaseUsername = username.toLowerCase();

        // Send the recaptchaToken with your login request
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
            username: lowerCaseUsername,
            password,
            recaptchaToken
        },
        { 
            withCredentials: true 
        })
        .then(response => {
            const userRole = response.data.role;
            const username = response.data.username;

            // Set user information in AuthContext
            setCurrentUser({
                username,
                role: userRole
            });
    
            // Log user access
            logUserAccess(navigate, refreshAccessToken)
                .then(logResponse => {
                    //console.log("Access log response:", logResponse);
                })
                .catch(logError => {
                    console.error("Error logging access:", logError);
                });
    
            // Based on the role, redirect the user to the appropriate view
            if (userRole === 'admin') {
                navigate('/adminview');
            } else {
                navigate('/userview');
            }
        })
        .catch(error => {
            console.log(error.response.data); // For debugging
            if (error.response && error.response.data.error) {
                setErrorMessage(error.response.data.error || error.response.data.message);
            } else {
                setErrorMessage("Unable to connect to the server. Please try again later.");
            }
        });
    };
    

    return (
        <div className="login-form-container">
            <h2>Login</h2>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <form className='login-form' onSubmit={handleSubmit}>
                <input 
                    className='login-input'
                    type="text" 
                    placeholder="Nombre de usuario" 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input 
                    className='login-input'
                    type="password" 
                    placeholder="Contraseña" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className='login-button' type="submit">Login</button>
            </form>
        </div>
    );
}

export default LoginForm;
