
//Date-Time formatter to GMT-5
function formatDateToLocal(dateString) {
    // Create a Date object from the UTC date-time string
    const dateObj = new Date(dateString);
    
    // Extract and format the local date-time values
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}


export function transformDateTimeValues(response_array, list_dateTime_fields){
    try{
        // Iterate over each object in the array
        return response_array.map(obj => {
            // For each key in the object
            for (let key in obj) {
                // If the key is one of the dateTime fields
                if (list_dateTime_fields.includes(key)) {
                    // Transform the value using formatDateToGMT5
                    obj[key] = formatDateToLocal(obj[key]);
                }
            }
            return obj;
        });
    }catch (err){
        console.log("Error tranforming array of objects")
        return err
    }
    
}


export function localToUTC(dateString, type) {
    try{
       let dateObj;
        
        if (type === "startDate") {
            dateObj = new Date(`${dateString}T00:00:00`);
        } else if (type === "endDate") {
            dateObj = new Date(`${dateString}T23:59:59`);
        } else {
            dateObj = new Date(dateString);  // Default behavior for other types
        }
        
        const year = dateObj.getUTCFullYear();
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getUTCDate()).padStart(2, '0');
        const hours = String(dateObj.getUTCHours()).padStart(2, '0');
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`; 
    }catch (err) {
        console.log('Error transforming dates')
        return err
    }
    
}
