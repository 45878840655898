import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './RegisterForm.css';

function ConfirmRegisterMail() {
    const [verificationStatus, setVerificationStatus] = useState('Verificando correo...');
    const [verificationInitiated, setVerificationInitiated] = useState(false);
    const location = useLocation();
    const [token, setToken] = useState(null);

    useEffect(()=>{
        setToken(new URLSearchParams(location.search).get('token'));
    }, [location.search])

    useEffect(() => {
        if (token && !verificationInitiated) {
            setVerificationInitiated(true);
            verifyEmail(token);
        } else {
            setVerificationStatus('Enlace no válido o expirado.');
        }
    }, [token, verificationInitiated]);

    const verifyEmail = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/verify-register-email?token=${token}`);
            if (response){
                if (response.data.message){
                    setVerificationStatus("Verificación exitosa.  Esta ventana se cerrará automaticamente en 15 segundos, o si quieres puedes cerrarla en este momento.");
                    // Close the window after 15 seconds if successful
                    setTimeout(() => {
                        window.close();
                    }, 15000)
                }
                if (response.data.error){
                    setVerificationStatus('Verification failed. Please try again or contact support. ' + response.data);
                }
            }
        } catch (error) {
            setVerificationStatus('Verification failed. Please try again or contact support. ' + error );
            // Do not close the window in case of an error
        }
    };

    return (
        <div className='secondary-pages-view'>
            <div style={{
                'width': '100%', 
                'display':'flex',
                'flexDirection':'column', 
                'justifyContent':'center',
                'alignItems':'center',
                'marginTop':'100px',
                'marginBottom':'20px',
                }}>
                <div className='reset-container'
                    style={{
                    'width': '600px', 
                    'boxShadow':'0px 4px 8px rgba(0, 0, 0, 0.1)',
                    'marginBottom':'20px'
                    }}>
                    <h2 style={{
                    'marginLeft': '20px'
                    }}>Página de verificación de email de registro</h2>
                    {verificationStatus && <div className="error-message" style={{
                    'marginLeft': '20px', 'marginTop': '20px'
                    }}>{verificationStatus}</div>}
                </div>
                
            </div>
        </div>
    );
}

export default ConfirmRegisterMail;
