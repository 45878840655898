//AuthContext.js
import React, { createContext, useContext, useState, useEffect, useCallback  } from 'react';
import axios from 'axios';  // Assuming you're using axios
import apiInstance from '../services/api'; 


const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

const setupInterceptor = (refreshAccessTokenFunc) => {
    apiInstance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            // Check for a 401 unauthorized response
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                // Attempt to refresh the access token
                const refreshSuccessful = await refreshAccessTokenFunc();
                if (refreshSuccessful) {
                    // If refresh is successful, retry the original request
                    return apiInstance(originalRequest);
                } else {
                    // If refresh fails, reject the promise (you might redirect to login here)
                    return Promise.reject(error);
                }
            }

            // For other errors, or if error.response is undefined (e.g., network error), just reject the promise
            return Promise.reject(error);
        }
    );
};


export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuth, setIsAuth] = useState(false)

    useEffect(()=>{
        // Derived state to check if user is authenticated
    setIsAuth(currentUser !== null);
    },[currentUser])
    
    
    // Function to initialize the user's authentication state
    useEffect(() => {
        const initializeAuthState = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/validate_token`, {
                    withCredentials: true
                });
                // Set user details based on the response
                const userData = {
                    'username': response.data.username,
                    'role': response.data.role,
                }
                setCurrentUser(userData);
            } catch (error) {
                console.error("Error during initial auth state setup:", error);
                setCurrentUser(null);
            }
        };
        initializeAuthState();
    }, []);

    

    const refreshAccessToken = useCallback(async () => {
        try {
            // Triggering token refresh. The backend should set new tokens in HTTP-only cookies.
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/token/refresh`, null, {
                withCredentials: true
            });

            // Set user details based on the response
            const userData = {
                'username': response.data.username,
                'role': response.data.role,
            }
            setCurrentUser(userData);
    
            // If refresh is successful, no need to update currentUser as tokens are in HTTP-only cookies
            return true; // Indicate that refresh was successful
        } catch (error) {
            console.error("Error during token refresh:", error);
            setCurrentUser(null); // Handle refresh failure (e.g., redirect to login)
            return false; // Indicate that refresh failed
        }
    }, []);
    

    useEffect(() => {
        setupInterceptor(refreshAccessToken);
    }, [refreshAccessToken]);

    const logout = async () => {
        try {
            // Clear any related states or data on the frontend
            setCurrentUser(null);
            setIsAuth(false);
            
            // Make a request to logout on the backend (this should invalidate the refresh token)
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logout`, null, {
                withCredentials: true  // This will send the HTTPOnly cookie back to the server
            });
            
        } catch (error) {
            console.error("Error during logout:", error);
            // Handle the error appropriately (e.g., show an error message)
        }
    };

    const value = {
        currentUser,
        setCurrentUser,
        isAuth,
        refreshAccessToken,  
        logout
    };

    console.log('AuthProvider has mounted')

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
