import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RegisterForm.css';

function ResetPasswordMail() {
    const [verificationStatus, setVerificationStatus] = useState('Verificando correo...');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [loading, setLoading] = useState(false);

    // Function to generate reCAPTCHA token
    const generateRecaptchaToken = async () => {
        if (window.grecaptcha && window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(async () => {
                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: 'passwordReset'});
                setRecaptchaToken(token);
            });
        }
    };
    
    // Generate token on component mount
    useEffect(() => {
        generateRecaptchaToken();
    }, []);

    const handleUpdate = async () => {
        try {
            setLoading(true); // Start loading

            // Refresh the reCAPTCHA token
            await generateRecaptchaToken();

            // Convert username to lowercase before submitting
            const lowerCaseEmail = email.toLowerCase();

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/password-reset-send-email`, {
                email: lowerCaseEmail,
                recaptchaToken
            });
            if (response.status === 200){
                navigate('/auth/pending-verification');
        } else {
            setVerificationStatus("No es posible actualizar la contraseña.");
        }
        } catch (err) {
            if (err.response && err.response.data.error) {
                setVerificationStatus(err.response.data.error);
            } else {
                setVerificationStatus("No es posible realizar la actualización.");
            }
        }
    };

    return (
        <div className='secondary-pages-view'>
            <h3>Cambiar Contraseña</h3>
            {verificationStatus && <div className="error-message">{verificationStatus}</div>}
            {loading ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div>Procesando solicitud de registro.</div>
                    <div className="spinner"></div>
                </div>
                ) : (
                <form className='login-form-modal'>
                    <input 
                        className='login-input-modal'
                        type="email" 
                        placeholder="Correo electrónico" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button onClick={(e)=> {e.preventDefault(); handleUpdate()}}
                        className='login-button' >
                        Cambiar contraseña
                    </button>
                </form>
            )}
            
        </div>
    );
}

export default ResetPasswordMail;
