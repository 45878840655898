// src/components/AdminAdminsView.jsx
import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../../context/AuthContext';
import { getAdmins } from '../../helpers/api';

function AdminAdminsView() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate()
    const { currentUser, refreshAccessToken } = useAuth();

    useEffect(() => {
        if (currentUser){
            async function fetchUsers() {
                try {
                    const result = await getAdmins( navigate, refreshAccessToken);
                    setUsers(result);
                } catch (error) {
                    console.log('error:', error)
                    if (error.message === "UNAUTHORIZED") {
                        navigate('/auth');
                    } else {
                        setError(error.message)
                        setUsers(null);
                        console.error("Error fetching users:", error);
                    }
                }
            }
            fetchUsers();
        }
        
    }, [currentUser, navigate, refreshAccessToken]);

    const dataFetched = React.useMemo(() => users, [users]);
    const data = (dataFetched)?(dataFetched):([])
              
    const columns = React.useMemo(
        () => [
            {
                Header: 'ID Usuario',
                accessor: 'id',
            },
            {
                Header: 'Nombre de usuario',
                accessor: 'username',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Nombres',
                accessor: 'first_name',
            },
            {
                Header: 'Apellidos',
                accessor: 'last_name',
            },
            {
                Header: 'Créditos',
                accessor: 'current_credits',
            },
            {
                Header: 'Creditos pentientes',
                accessor: 'pending_credits',
            },
            {
                Header: 'Celular',
                accessor: 'cellphone',
            },
            {
                Header: 'Empresa',
                accessor: 'enterprise_name',
            },
            {
                Header: 'Usuario desde',
                accessor: 'date_joined',
            },
            {
                Header: 'Último Login',
                accessor: 'last_login',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className="management-view">
            <h2>Listado de administradores</h2>
            {error && <div className="error">{error}</div>}

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default AdminAdminsView;
