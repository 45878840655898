import React, { useState, useEffect, useCallback} from 'react';
import { useTable } from 'react-table';
import { getCreditRecords, getUserCreditsBalance } from '../../helpers/api';
//import './UserCreditsBalanceBase.css';
import { useNavigate } from 'react-router-dom';
import { useAuth} from '../../context/AuthContext';
import { validateUsername} from '../../helpers/inputsValidation';

function UserCreditBalanceBase({role}) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [username, setUsername] = useState('');
    const [creditRecords, setCreditRecords] = useState([]);
    const [creditsBalance, setCreditsBalance] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { currentUser, refreshAccessToken, isAuth } = useAuth();


    // Set username based on role
    useEffect(() => {
        if (currentUser){
            if (role === 'Admin') {
                setUsername('');  // Or however you want to set it for admin
            } else {
                setUsername(currentUser.username);
            }
        }
    }, [role, currentUser]);

    const balance = useCallback( async() => {
        const result_balance = await getUserCreditsBalance(username, navigate, refreshAccessToken);
        setCreditsBalance(result_balance[0].credits_balance)
    }, [navigate, refreshAccessToken, username]);
    

    const handleSubmit = useCallback( async (e) => {
        if (e) e.preventDefault();  // Check if event is passed to prevent default behavior
        //Input error handling
        if (!validateUsername(username, setError,setCreditRecords)) { return; } 
        //En of Input error handling
        try {
            const result_records = await getCreditRecords( username, startDate, endDate, navigate, refreshAccessToken);
            setCreditRecords(result_records);
            balance();
            setError('');
        } catch (err) {
            if (err.response.status === 404 || err.response.status === 400) {
                setError('Usuario o búsqueda no válida.');
                setCreditRecords(null);
            } else {
                setError(err.message || 'Error fetching data.');
                setCreditRecords(null);
            }
        }
    },[balance, username, startDate, endDate, navigate, refreshAccessToken]);

    useEffect(() => {
        // This will fetch data when the component mounts and if the token changes
        if (isAuth && username) {
            const fetchData = async () => {
                //Input error handling
                if (!validateUsername(username, setError,setCreditRecords)) { return; } 
                //En of Input error handling
                try {
                    const result_records = await getCreditRecords(currentUser.username, startDate, endDate, navigate, refreshAccessToken);
                    setCreditRecords(result_records);
                    const result_balance = await getUserCreditsBalance(currentUser.username, navigate, refreshAccessToken);
                    setCreditsBalance(result_balance[0].credits_balance);
                    setError('');
                } catch (err) {
                    setError(err.message || 'Error fetching data.');
                    setCreditRecords(null);
                }
            };
            fetchData();
        }
    }, [isAuth]);

    const dataFetched = React.useMemo(() => creditRecords, [creditRecords]);
    const data = (dataFetched)?(dataFetched):([])

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Fecha',
                accessor: 'date',
            },
            {
                Header: 'Cantidad',
                accessor: 'amount',
            },
            {
                Header: 'Tipo de movimiento',
                accessor: 'type',
            },
            {
                Header: 'Herramienta utilizada',
                accessor: 'descript',
            },
            {
                Header: 'Balance de créditos',
                accessor: 'balance',
            },
            {
                Header: 'Estado',
                accessor: 'status',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className={`${role === 'Admin' ? 'management-view' : 'management-view'}`}>
            <h2>{role === 'Admin' ? 'Balance de créditos por usuario.' : 'Mis créditos'}</h2>
            <br></br>
            <h3>Balance actual de créditos: {username === '' ? '' : creditsBalance } </h3>
            
            <form className='form-container' onSubmit={handleSubmit}>
                {role === 'Admin' && (
                    <div className='form-field-container'>
                        <label>Usuario:</label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                )}
                <div className='form-field-container'>
                    <label>Fecha inicio (opcional):</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className='form-field-container'>
                    <label>Fecha final (opcional):</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <button type="submit">Buscar</button>
            </form>

            {error && <div className="error">{error}</div>}

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default UserCreditBalanceBase;
