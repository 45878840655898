import React from 'react';
import './HomeView.css';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import ProductsInfo from './ProductsInfo/ProductsInfo';
import ClientsInfo from './ClientsInfo/ClientsInfo';
import LandingView from "./LandingView"

function HomeView() {
    return (
        <div className='landing-page-view'>
            <div className="home-view-container">
                <LandingView />

                {/* <CompanyInfo />
                <ProductsInfo />
                <ClientsInfo /> */}

            </div>    
        </div>
    );
}

export default HomeView;
