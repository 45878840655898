//'AdminNewTransaction.jsx'
import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminNewTransaction.css'

function AdminNewTransaction({role}) {
    const [formData, setFormData] = useState({
        target_email: '',
        confirm_target_email: '',
        password: '',
        trans_type: 'Compra',
        amount_paid: '',
        credits_purchased: '',
        trans_platform: 'Portal ProcessMática',
        acceptTerms: false
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModalTransactionConfirm, setShowModalTransactionConfirm] = useState(false);


    // Function to generate reCAPTCHA token
    const generateRecaptchaToken = async () => {
        if (window.grecaptcha && window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(async () => {
                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: 'transactionSubmit'});
                setRecaptchaToken(token);
            });
        }
    };

    // Generate token on component mount
    useEffect(() => {
        generateRecaptchaToken();
    }, []);

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        setMessage('');
        event.preventDefault();

        if (!formData.acceptTerms) {
            setMessage("Debes aceptar la autorización de la transacción.");
            return;
        }

        if (formData.target_email !== formData.confirm_target_email) {
            setMessage("Emails do not match!");
            return;
        }

        setLoading(true); // Start loading

        // Refresh the reCAPTCHA token
        await generateRecaptchaToken();

        // Convert target_email to lowercase before submitting
        const payload = {
            ...formData,
            target_email: formData.target_email.toLowerCase(),
            recaptchaToken
        };
        delete payload.confirm_target_email;

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/new_transaction`, payload, 
        { 
            withCredentials: true 
        })
        .then(response => {
            setFormData({
                target_email: '',
                confirm_target_email: '',
                password: '',
                trans_type: '',
                amount_paid: '',
                credits_purchased: '',
                trans_platform: '',
                acceptTerms: false
            })
            setShowModalTransactionConfirm(true);
        })
        .catch(error => {
            if (error.response && error.response.data.error) {
                setMessage(error.response.data.error);
            } else {
                setMessage("Unable to connect to the server. Please try again later.");
            }
        })
        .finally(() => {
            setLoading(false); // Stop loading when request is complete
        });
    };


    return (
        <div className="register-form-container" style={{
            'width':'50%',
            'minWidth':'500px'
        }}>
            <h2>Crear una nueva transacción</h2>
            {message && <div className="error-message">{message}</div>}
            {loading ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div>Procesando solicitud de registro.</div>
                    <div className="spinner"></div>
                </div>
            ) : (
                <form className='register-form' onSubmit={handleSubmit}>
                    <input 
                        className='register-input'
                        type="email" 
                        name="target_email"
                        placeholder="Correo del usuario destino" 
                        value={formData.target_email}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="email" 
                        name="confirm_target_email"
                        placeholder="Confirmar correo del usuario destino" 
                        value={formData.first_name}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="text" 
                        name="trans_type"
                        placeholder="Tipo transacción" 
                        value={formData.trans_type}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="number"
                        name="amount_paid"
                        placeholder="Cantidad pagada" 
                        value={formData.amount_paid}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="number"
                        name="credits_purchased"
                        placeholder="Créditos comprados" 
                        value={formData.credits_purchased}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="text" 
                        name="trans_platform"
                        placeholder="Plataforma" 
                        value={formData.trans_platform}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="password" 
                        name="password"
                        placeholder="Contraseña" 
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                    <div className="checkbox-container">
                        <input 
                            type="checkbox" 
                            name="acceptTerms"
                            checked={formData.acceptTerms}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="acceptTerms">He corroborado la información de la transacción</label>
                    </div>
                    <button className='register-button' type="submit">Registrar Trasacción</button>
                </form>
            )}
            {showModalTransactionConfirm && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={() => setShowModalTransactionConfirm(false)}>&times;</span>
                        <div>
                            Transacción creada con éxito.
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminNewTransaction;
