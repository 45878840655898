//'UserProfileBase.jsx'
import React, { useState, useEffect, useCallback } from 'react';
import { getUserInformation, updateUserInfo } from '../../helpers/api';
import './UserProfileBase.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ChangePassword from '../Auth/ChangePassword';

function UserProfileBase({role}) {
    const [userInfo, SetUserInfo] = useState({
        "email":'',
        "enterprise_name":'',
        "cellphone":'',
        "machine_id":'',
        "first_name":'',
        "last_name":'',
        "date_joined":'',
        "last_login":''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {currentUser, refreshAccessToken, isAuth } = useAuth();
    const [username, setUsername] =  useState('');
    const [editableField, setEditableField] = useState("");
    const [editableFields, setEditableFields] = useState({});
    const [editableValues, setEditableValues] = useState({});
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [showModalPassChange, setShowModalPassChange] = useState(false);

    const canUpdate = () => {
        return Object.keys(editableFields).some(field => editableFields[field]) && 
               Object.keys(editableValues).length > 0;
    };

    useEffect(() => {
        if (currentUser) {
            setUsername(currentUser.username);
        } else {
            refreshAccessToken();
        }
    }, [currentUser, refreshAccessToken]);

    const getUserInfo = useCallback(async () => {
        if ( username) {
            try {
                const result = await getUserInformation( username, navigate, refreshAccessToken);
                SetUserInfo(result[0]);
                setError('');
            } catch (err) {
                setError(err.message || 'Error fetching data.');
                SetUserInfo(null);
            }
        }
    },[ username, navigate, refreshAccessToken]);

    const handleEdit = (field) => {
        setEditableFields(prevState => ({ ...prevState, [field]: true }));
    };
    
    const handleUpdate = async () => {
        const fieldsToUpdate = {};
        for (let field in editableFields) {
            if (editableFields[field]) {
                fieldsToUpdate[field] = editableValues[field];
            }
        }
        if ( username){
            try {
                await updateUserInfo( fieldsToUpdate, navigate, refreshAccessToken);
                setShowModalUpdate(true);
                // Reset editable fields and re-fetch user info
                setEditableFields({});
                setEditableField('');
                getUserInfo();
            } catch (err) {
                if (err.response && err.response.data.error) {
                    setError(err.response.data.error);
                } else {
                    setError("No es posible realizar la actualización.");
                }
            }
        }
        
    };
    
    const handleCloseModalUpdate = () => {
        setShowModalUpdate(false);
    };

    const handleCloseModalPassChange = () => {
        setShowModalPassChange(false);
    };
    

    useEffect(() => {
        getUserInfo();
    }, [getUserInfo]);
    


    return (
        <div className={`${role === 'Admin' ? 'profile-management-view' : 'profile-management-view'}`}>
            <br></br>
            <br></br>
            <h3>Información del usuario</h3>
            <br></br>
            <div>
                {error && <div className="error">{error}</div>}
            </div>
            <form className='profile-form-container' >
                <div className='form-field-container'>
                    <label>Nombre de usuario:</label>
                    <input type="text" value={username} disabled={true} />
                    <div className='modify-field-div'></div>
                </div>
                <div className='form-field-container'>
                    <label>Email:</label>
                    <input type="text" 
                        value={editableValues.email || userInfo.email} 
                        disabled={editableField !== "email"}
                        onChange={(e) => {
                            if (e.target.value !== userInfo.email) {
                                setEditableValues(prevState => ({ ...prevState, email: e.target.value }));
                            } else {
                                const updatedValues = { ...editableValues };
                                delete updatedValues.email;
                                setEditableValues(updatedValues);
                            }
                        }}/>
                    <button 
                        className={ !(editableField === "email") ? 'modify-field-button' : 'disabled-modify-field-button'} 
                        onClick={(e) => { 
                            e.preventDefault(); 
                            setEditableField("email"); 
                            handleEdit('email');
                            }} 
                        disabled={editableField === "email"} 
                        >Edit</button>
                </div>
                <div className='form-field-container'>
                    <label>Teléfono celular:</label>
                    <input type="text" 
                        value={editableValues.cellphone || userInfo.cellphone} 
                        disabled={editableField !== "cellphone"}
                        onChange={(e) => {
                            if (e.target.value !== userInfo.cellphone) {
                                setEditableValues(prevState => ({ ...prevState, cellphone: e.target.value }));
                            } else {
                                const updatedValues = { ...editableValues };
                                delete updatedValues.cellphone;
                                setEditableValues(updatedValues);
                            }
                        }} />
                    <button 
                        className={ !(editableField === "cellphone") ? 'modify-field-button' : 'disabled-modify-field-button'} 
                            onClick={(e) => { 
                            e.preventDefault(); 
                            setEditableField("cellphone"); 
                            handleEdit('cellphone');
                            }}
                        disabled={editableField === "cellphone"} 
                        >Edit</button>
                </div>
                <div className='form-field-container'>
                    <label>Nombre:</label>
                    <input type="text" 
                        value={editableValues.first_name || userInfo.first_name} 
                        disabled={editableField !== "first_name"}
                        onChange={(e) => {
                            if (e.target.value !== userInfo.first_name) {
                                setEditableValues(prevState => ({ ...prevState, first_name: e.target.value }));
                            } else {
                                const updatedValues = { ...editableValues };
                                delete updatedValues.first_name;
                                setEditableValues(updatedValues);
                            }
                        }} />
                    <button 
                        className={ !(editableField === "first_name") ? 'modify-field-button' : 'disabled-modify-field-button'} 
                        onClick={(e) => { 
                            e.preventDefault(); 
                            setEditableField("first_name"); 
                            handleEdit('first_name');
                            }} 
                        disabled={editableField === "first_name"} 
                        >Edit</button>
                </div>
                <div className='form-field-container'>
                    <label>Apellido:</label>
                    <input type="text" 
                        value={editableValues.last_name || userInfo.last_name} 
                        disabled={editableField !== "last_name"} 
                        
                        onChange={(e) => {
                            if (e.target.value !== userInfo.last_name) {
                                setEditableValues(prevState => ({ ...prevState, last_name: e.target.value }));
                            } else {
                                const updatedValues = { ...editableValues };
                                delete updatedValues.last_name;
                                setEditableValues(updatedValues);
                            }
                        }} />
                    <button 
                        className={ !(editableField === "last_name") ? 'modify-field-button' : 'disabled-modify-field-button'} 
                        onClick={(e) => { 
                            e.preventDefault(); 
                            setEditableField("last_name"); 
                            handleEdit('last_name');
                            }} 
                        disabled={editableField === "last_name"} 
                        >Edit</button>
                </div>
                <div className='form-field-container'>
                    <label>Empresa:</label>
                    <input type="text" 
                    value={editableValues.enterprise_name || userInfo.enterprise_name} 
                    disabled={editableField !== "enterprise_name"} 
                    onChange={(e) => {
                        if (e.target.value !== userInfo.enterprise_name) {
                            setEditableValues(prevState => ({ ...prevState, enterprise_name: e.target.value }));
                        } else {
                            const updatedValues = { ...editableValues };
                            delete updatedValues.enterprise_name;
                            setEditableValues(updatedValues);
                        }
                    }}/>
                    <button 
                        className={ !(editableField === "enterprise_name") ? 'modify-field-button' : 'disabled-modify-field-button'} 
                        onClick={(e) => { 
                            e.preventDefault(); 
                            setEditableField("enterprise_name");  
                            handleEdit('enterprise_name');
                            }} 
                        disabled={editableField === "enterprise_name"} 
                        >Edit</button>
                </div>

                <div className='form-field-container'>
                    <label>Usuario desde:</label>
                    <input type="text" value={userInfo.date_joined} disabled={true} />
                    <div className='modify-field-div'></div>
                </div>
                <div className='form-field-container'>
                    <label>ID máquina:</label>
                    <input type="text" value={userInfo.machine_id} disabled={true} />
                    <div className='modify-field-div'></div>
                </div>
                <button 
                    onClick={(e)=> {e.preventDefault(); handleUpdate()}}
                    disabled={!canUpdate()}
                    className= {!canUpdate() ? 'disabled-button' : 'enabled-button'}
                >
                    Actualizar
                </button>
            </form>
            <br/>
            <div>
                <h3>Cambiar contraseña</h3>
                <div className='password-change-div'>
                    <div>Para cambiar contraseña haga click en este  </div>
                    <button className='password-change-button' onClick={() => setShowModalPassChange(true)}>Botón</button>
                </div>
            </div>
            {showModalUpdate && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={handleCloseModalUpdate}>&times;</span>
                        La información se ha actualizado.
                    </div>
                </div>
            )}
            {showModalPassChange && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={handleCloseModalPassChange}>&times;</span>
                        <ChangePassword />
                    </div>
                </div>
            )}

        </div>
    );
}

export default UserProfileBase;
