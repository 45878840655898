//ContactUs.jsx
import React from 'react';
import './ContactUs.css';


function ContactUs() {
    return (
        <div className='secondary-pages-view'>
            <div className="secondary-pages-container">
                <div className='section-header'> CONTÁCTANOS </div>
                <div className='secondary-page-main-body'>
                    <div className='support-subtittle'>Puedes contactarte con nosotros a través de los diferentes medios:</div>
                    <ul>
                        <li>Contacto de soporte: <a href='mailto:soporte@processmatica.com.co'>soporte@processmatica.com.co</a></li>
                        <li>Buzón de sugerencias</li>
                        <li>Nuestras redes socials:</li>
                        <ul>
                            <li>LinkedIn</li>
                            <li>Instagram</li>
                            <li>tiktok</li>
                        </ul>
                    </ul>
                </div>
                
                
            </div>
        </div>
    );
}

export default ContactUs;