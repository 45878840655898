import React from 'react';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import UserProfileBase from '../Common/UserProfileBase';
import AdminUsersView from './AdminUsersView';
import AdminAdminsView from './AdminAdminsView';
import AdminRecentLogsView from './AdminRecentLogsView';
import UserLogsBase from '../Common/UserLogsBase';
import UserUtilizationsBase from '../Common/UserUtilizationsBase';
import AdminAllUtilizations from './AdminAllUtilizations';
import AdminFeatUtilization from './AdminFeatUtilization';
import UserCredistBalanceBase from '../Common/UserCreditsBalanceBase';
import AdminGetAllTransactions from './AdminGetAllTransactions';
import UserTransactionsBase from '../Common/UserTransactionsBase';
import AdminNewTransaction from './AdminNewTransaction';
import AdminAllErrorLogs from './AdminAllErrorLogs';
import UserErrorsBase from '../Common/UserErrorsBase';
import UserWelcomeBase from '../Common/UserWelcomeBase';
import AdminFeatErrors from './AdminFeatErrors';
import { useAuth } from '../../context/AuthContext';
import './AdminView.css';

function AdminView() {
    const { refreshAccessToken, currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            refreshAccessToken();
        } 
    }, [currentUser, refreshAccessToken]);

    return (
        <div className='secondary-pages-view'>
            <div className="admin-view-container">
                <AdminSidebar />
                <div className="admin-work-area">
                    <Routes>
                        <Route path="adminWelcome" element={<UserWelcomeBase />} />
                        <Route path="profile" element={<UserProfileBase />} />
                        <Route path="users" element={<AdminUsersView />} />
                        <Route path="admins" element={<AdminAdminsView />} />
                        <Route path="recent-logs" element={<AdminRecentLogsView />} />
                        <Route path="recent-logs/user" element={<UserLogsBase role="Admin" />} />
                        <Route path="utilizations/user" element={<UserUtilizationsBase role="Admin" />} />
                        <Route path="all-utilizations" element={<AdminAllUtilizations />} />
                        <Route path="features" element={<AdminFeatUtilization />} />
                        <Route path="creditsBalance/user" element={<UserCredistBalanceBase role="Admin" />} />
                        <Route path="all-transactions" element={<AdminGetAllTransactions />} />
                        <Route path="transactions/user" element={<UserTransactionsBase role="Admin" />} />
                        <Route path="new-transaction" element={<AdminNewTransaction />} />
                        <Route path="all-error-logs" element={<AdminAllErrorLogs />} />
                        <Route path="user-errors/user" element={<UserErrorsBase role="Admin" />} />
                        <Route path="feature-errors/features" element={<AdminFeatErrors />} />
                        {/* Add a fallback route */}
                        <Route path="*" element={<UserWelcomeBase />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default AdminView;
