import React, {  } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './AdminSidebar.css';

function AdminSidebar() {
    const location = useLocation();
    const selectedView = location.pathname;

    return (
        <div className="adminSidebarContainer">
            <div className="sidebarLinksContainer">
                <div className={`sidebar-link-div ${selectedView === '/adminview/profile' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/profile" 
                        className={`sidebarLink ${selectedView === '/adminview/profile' ? 'selected' : ''}`} >
                        Mi información de perfil
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/adminview/users' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/users" 
                        className={`sidebarLink ${selectedView === '/adminview/users' ? 'selected' : ''}`} >
                        Listado de usuarios
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/admins' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/admins" 
                        className={`sidebarLink ${selectedView === '/adminview/admins' ? 'selected' : ''}`} >
                        Listado de Administradores
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/adminview/recent-logs' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/recent-logs" 
                        className={`sidebarLink ${selectedView === '/adminview/recent-logs' ? 'selected' : ''}`} >
                        Consulta de accesos web
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/recent-logs/user' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/recent-logs/user" 
                        className={`sidebarLink ${selectedView === '/adminview/recent-logs/user' ? 'selected' : ''}`} >
                        Consulta de accesos web por usuario
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/adminview/all-utilizations' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/all-utilizations" 
                        className={`sidebarLink ${selectedView === '/adminview/all-utilizations' ? 'selected' : ''}`} >
                        Consultar todas las utilizaciones
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/utilizations/user' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/utilizations/user" 
                        className={`sidebarLink ${selectedView === '/adminview/utilizations/user' ? 'selected' : ''}`} >
                        Consulta utilizaciones por usuario
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/adminview/features' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/features" 
                        className={`sidebarLink ${selectedView === '/adminview/features' ? 'selected' : ''}`} >
                        Consultar utilizaciones por Herramienta
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/adminview/creditsBalance/user' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/creditsBalance/user" 
                        className={`sidebarLink ${selectedView === '/adminview/creditsBalance/user' ? 'selected' : ''}`} >
                        Consulta balance de créditos por usuario
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/all-transactions' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/all-transactions" 
                        className={`sidebarLink ${selectedView === '/adminview/all-transactions' ? 'selected' : ''}`} >
                        Consulta todas las transacciones
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/transactions/user' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/transactions/user" 
                        className={`sidebarLink ${selectedView === '/adminview/transactions/user' ? 'selected' : ''}`} >
                        Consulta de transacciones por usuario
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/new-transaction' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/new-transaction" 
                        className={`sidebarLink ${selectedView === '/adminview/new-transaction' ? 'selected' : ''}`} >
                        Crear una nueva transacción
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/adminview/all-error-logs' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/all-error-logs" 
                        className={`sidebarLink ${selectedView === '/adminview/all-error-logs' ? 'selected' : ''}`} >
                        Consultar registros de errores
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/user-errors/user' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/user-errors/user" 
                        className={`sidebarLink ${selectedView === '/adminview/user-errors/user' ? 'selected' : ''}`} >
                        Consultar registros de errores por usuario
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/adminview/feature-errors/features' ? 'selected' : ''}`} >
                    <Link 
                        to="/adminview/feature-errors/features" 
                        className={`sidebarLink ${selectedView === '/adminview/feature-errors/features' ? 'selected' : ''}`} >
                        Consultar registros de errores por herramienta
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AdminSidebar;
