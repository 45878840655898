import React  from 'react';
import './LandingView.css';
import landingBackgroundImage from '../../assets/images/landing-image_02.png';
import landingImage from '../../assets/images/especialistas-gdb-anla.png';

function LandingView() {
    return (
        <div className="landing-view-container" style={{ backgroundImage: `url(${landingBackgroundImage})` }}>
            <div className='landing-content-wrapper'>
                <div className='landing-text-container'>
                    <div className='landing-text-wrapper'>
                        <div className='landing-title-div'>
                            <div className='landing-title'>Aseguramiento de calidad para Geodatabases</div>
                        </div>
                        <div className='landing-desc-div'>
                            <div className='landing-desc'>
                                Minimice el riesgo en la radicación de la información 
                                geográfica ante las Autoridades Ambientales.
                            </div>
                        </div>
                        <div className='landing-hero-action-container'>
                            <div className='landing-hero-action-content-wrapper'>
                                <div className='landing-hero-title'>Registrese para probar gratis nuestras herramientas</div>
                                <div className='landing-hero-button-container'>
                                    <a href='/auth'>
                                        <button className='landing-hero-button' >Ir a Registro</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='landing-hero-image-container'>
                    <div className='landing-hero-content'>
                        <div className='landing-hero-image-wrapper' style={{ backgroundImage: `url(${landingImage})` }}>
                            {/* Aquí se puede poner texto sobre la imagen. */}
                        </div>
                        <div className='landing-hero-action-container'>
                            <div className='landing-hero-action-content-wrapper'>
                                <div className='landing-hero-button-container'>
                                    <a href='/product-services'>
                                        <button className='landing-hero-button' >Descúbrelo</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    );
}

export default LandingView;