import React, { useState, useEffect, useCallback } from 'react';
import { useTable } from 'react-table';
import { getAllUtilizations } from '../../helpers/api';
import './AdminAllUtilizations.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { validateMaxCount } from '../../helpers/inputsValidation';

function AdminAllUtilizations() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [maxCount, setMaxCount] = useState('20');
    const [utilizations, setUtilizations] = useState([]);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const { refreshAccessToken, isAuth } = useAuth();


    const handleSubmit = useCallback(async (e) => {
        if (e) e.preventDefault();  // Check if event is passed to prevent default behavior
        //Input error handling
        if (!validateMaxCount(maxCount, setError,setUtilizations)) { return; }
        //En of Input error handling
        if (isAuth){
            try {
                const result = await getAllUtilizations( startDate, endDate, maxCount, navigate, refreshAccessToken);
                setUtilizations(result);
                if (result.length === 0){
                    setError('No se encontraron utilizaciones.');
                    setUtilizations(null);
                }else{
                    setError('');
                }
            } catch (err) {
                setError(err.message || 'Error fetching data.');
                setUtilizations(null);
            }
        }
    }, [maxCount, isAuth, startDate, endDate, navigate, refreshAccessToken]);

    useEffect(() => {
        const fetchDataDueToTokenChange = async () => {
            if (isAuth) {
                //Input error handling
                if (!validateMaxCount(maxCount, setError,setUtilizations)) { return; }
                //En of Input error handling
                try {
                    const result = await getAllUtilizations(startDate, endDate, maxCount, navigate, refreshAccessToken);
                    setUtilizations(result);
                    if (result.length === 0){
                        setError('No se encontraron utilizaciones.');
                        setUtilizations(null);
                    }else{
                        setError('');
                    }
                } catch (err) {
                    setError(err.message || 'Error fetching data.');
                    setUtilizations(null);
                }
            }
        };
        fetchDataDueToTokenChange();
    }, [isAuth]);
    

    const dataFetched = React.useMemo(() => utilizations, [utilizations]);
    const data = (dataFetched)?(dataFetched):([])

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID utilización',
                accessor: 'utilization_id',
            },
            {
                Header: 'Usuario',
                accessor: 'username',
            },
            {
                Header: 'ID herramienta',
                accessor: 'feature_id',
            },
            {
                Header: 'Herramienta',
                accessor: 'feature_name',
            },
            {
                Header: 'Fecha de uso',
                accessor: 'used_date',
            },
            {
                Header: 'Fecha de terminación',
                accessor: 'finish_date',
            },
            {
                Header: 'FC Records 90%',
                accessor: 'fc_records90',
            },
            {
                Header: 'TB Records 90%',
                accessor: 'tb_records90',
            },
            {
                Header: 'Score',
                accessor: 'score',
            },
            {
                Header: '% Score',
                accessor: 'prcnt_score_level',
            },
            {
                Header: 'Creditos usados',
                accessor: 'credits_used',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className="management-view">
            <h2>Datos de utilización para todos los usuarios</h2>
            
            <form className='form-container' onSubmit={handleSubmit}>
                <div className='form-field-container'>
                    <label>Fecha inicial (opcional):</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className='form-field-container'>
                    <label>Fecha final (opcional):</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className='form-field-container'>
                    <label>Registros (opcional):</label>
                    <input type="number" value={maxCount} onChange={(e) => setMaxCount(e.target.value)} />
                </div>

                <button type="submit">Buscar</button>
            </form>

            {error && <div className="error">{error}</div>}

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default AdminAllUtilizations;