import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './RegisterForm.css';
import { logPasswordChange } from '../../helpers/api';
import { useAuth } from '../../context/AuthContext';

function ConfirmChangePasswordMail() {
    const [verificationStatus, setVerificationStatus] = useState('');
    const [newpassword, setNewpassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const location = useLocation();
    const [token, setToken] = useState(null);
    const {setCurrentUser, refreshAccessToken } = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        setToken(new URLSearchParams(location.search).get('token'));
    }, [location.search])


    const handleUpdate = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/verify-reset-password-email`,{
                new_password: newpassword,
                confirm_new_password: confirmNewPassword,
                token: token
            });
            if (response.status === 200){
                const userRole = response.data.role;
                const username = response.data.username;

                // Set user information in AuthContext
                setCurrentUser({
                    username,
                    role: userRole
                });
            logPasswordChange('Password Change (reset)', navigate, refreshAccessToken)
            setVerificationStatus("La contraseña fue actualizada con éxito.");
            // Reset fields
            setNewpassword('');
            setConfirmNewPassword('');
        } else {
            setVerificationStatus("No es posible actualizar la contraseña.");
        }
        } catch (err) {
            if (err.response && err.response.data.error) {
                setVerificationStatus(err.response.data.error);
            } else {
                setVerificationStatus("No es posible realizar la actualización.");
            }
        }
    };

    return (
        <div className='secondary-pages-view'>
            <div style={{
                'width': '100%', 
                'display':'flex',
                'flexDirection':'column', 
                'justifyContent':'center',
                'alignItems':'center',
                'marginTop':'100px'
                }}>
                <div className='reset-container'
                    style={{
                    'width': '400px', 
                    'boxShadow':'0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}>
                    <h3 style={{
                    'marginLeft': '20px'
                    }}>Cambiar Contraseña</h3>
                    {verificationStatus && <div className="error-message">{verificationStatus}</div>}
                    <form className='login-form'>
                        <input 
                            style={{ 'width':'90%'}}
                            className='login-input-reset'
                            type="password" 
                            placeholder="Nueva contraseña" 
                            value={newpassword}
                            onChange={(e) => setNewpassword(e.target.value)}
                        />
                        <input 
                            style={{ 'width':'90%'}}
                            className='login-input-reset'
                            type="password" 
                            placeholder="Confirmación nueva contraseña" 
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                        <button onClick={(e)=> {e.preventDefault(); handleUpdate()}}
                            className='login-button' 
                            style={{ 'width':'90%'}}>
                            Cambiar contraseña
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ConfirmChangePasswordMail;
