
import React from 'react';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import UserSidebar from './UserSidebar';
import UserProfileBase from '../Common/UserProfileBase';
import UserLogsBase from '../Common/UserLogsBase';
import UserUtilizationsBase from '../Common/UserUtilizationsBase';
import UserCredistBalanceBase from '../Common/UserCreditsBalanceBase';
import UserTransactionsBase from '../Common/UserTransactionsBase';
import UserErrorsBase from '../Common/UserErrorsBase';
import SupportView from '../Support/SupportView';
import UserWelcomeBase from '../Common/UserWelcomeBase';
import './UserView.css';  // Assuming you have a CSS file for styles
import { useAuth } from '../../context/AuthContext';

function UserView() {
    const { refreshAccessToken, currentUser } = useAuth();

    // Set username based on role
    useEffect(() => {
        if (!currentUser) {
            refreshAccessToken()
        } 
    }, [currentUser, refreshAccessToken]);

    return (
        <div className='secondary-pages-view' >
            <div className="user-view-container">
                <UserSidebar />
                <div className="user-work-area">
                    <Routes>
                        {/* Define your user routes here */}
                        <Route path="userWelcome" element={<UserWelcomeBase />} />
                        <Route path="profile" element={<UserProfileBase  role="User" />} />
                        <Route path="recent-logs" element={<UserLogsBase role="User" />} />
                        <Route path="utilizationsBase" element={<UserUtilizationsBase role="User" />} />
                        <Route path="creditsBalance" element={<UserCredistBalanceBase role="User" />} />
                        <Route path="transactions" element={<UserTransactionsBase role="User" />} />
                        <Route path="user-errors" element={<UserErrorsBase role="User" />} />
                        <Route path="support/*" element={<SupportView />} />
                        <Route path="*" element={<UserWelcomeBase />} />
                    </Routes>
                </div>
            </div>
        </div>

    );
}

export default UserView;
