import React from 'react';
import { Link } from 'react-router-dom';



function SupportView() {
    return (
        <div className='secondary-pages-view'>
            <div className="secondary-pages-container">
                <div className='section-header'>
                    SOPORTE
                </div>
                <div className='secondary-page-main-body'>
                    <div className='support-subtittle'>
                        Aquí puedes consultar nuestros Términos de uso del servicio:
                        <br/>
                        <Link to= '/support/terminos-uso-servicio'>Términos de uso del servicio</Link>
                    </div>
                    <div className='support-subtittle'>
                        Aquí puedes consultar nuestra política de tratamiento de datos:
                        <br/>
                        <Link to= '/support/politica-tratamiento-datos'>Política de tratamiento de datos</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportView;