import React, { useState, useEffect, useCallback } from 'react';
import { useTable } from 'react-table';
import { getUserLogs } from '../../helpers/api';
//import './UserLogsBase.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { validateUsername, validateMaxCount } from '../../helpers/inputsValidation';

function UserLogsBase({role}) {
    const [username, setUsername] =  useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [maxCount, setMaxCount] = useState('20');
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {currentUser, refreshAccessToken, isAuth } = useAuth();

    
    // Set username based on role
    useEffect(() => {
        if (currentUser){
            if (role === 'Admin') {
                setUsername('');  // Or however you want to set it for admin
            } else {
                setUsername(currentUser.username);
            }
        }
        
    }, [role, currentUser]);

    const handleSubmit = useCallback(async (e) => {
        if (e) e.preventDefault();  // Check if event is passed to prevent default behavior
        //Input error handling
        if (!validateUsername(username, setError,setLogs)) { return; } 
        if (!validateMaxCount(maxCount, setError,setLogs)) { return; }
        //En of Input error handling
        if (username){
            try {
                const result = await getUserLogs(username, startDate, endDate, maxCount, navigate, refreshAccessToken);
                setLogs(result);
                if (result.length === 0){
                    setError('El usuario no tiene accessos.');
                    setLogs(null);
                }else{
                    setError('');
                }
            } catch (err) {
                if (err.response.status === 404 || err.response.status === 400) {
                    setError('Usuario o búsqueda no válida.');
                    setLogs(null);
                } else {
                    setError(err.message || 'Error fetching data.');
                    setLogs(null);
                }
            }
        }
        
    }, [username, startDate, endDate, maxCount, navigate, refreshAccessToken]);

    useEffect(() => {
        const fetchDataDueToTokenChange = async () => {
            if (isAuth && username) {
                //Input error handling
                if (!validateUsername(username, setError,setLogs)) { return; } 
                if (!validateMaxCount(maxCount, setError,setLogs)) { return; }
                //En of Input error handling
                try {
                    const result = await getUserLogs(username, startDate, endDate, maxCount, navigate, refreshAccessToken);
                    setLogs(result);
                    setError('');
                } catch (err) {
                    setError(err.message || 'Error fetching data.');
                    setLogs(null);
                }
            }
        };
        fetchDataDueToTokenChange();
    }, [isAuth]);

    const dataFetched = React.useMemo(() => logs, [logs]);
    const data = (dataFetched)?(dataFetched):([])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Usuario',
                accessor: 'username',
            },
            {
                Header: 'Acceso',
                accessor: 'access_time',
            },
            {
                Header: 'Tipo Acceso',
                accessor: 'access_type',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className={`${role === 'Admin' ? 'management-view' : 'management-view'}`}>
            <h2>{role === 'Admin' ? 'Accesos web por usuario' : 'Mis accesos web'}</h2>
            
            <form className='form-container' onSubmit={handleSubmit}>
                {role === 'Admin' && (
                    <div className='form-field-container'>
                        <label>Usuario:</label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                )}
                <div className='form-field-container'>
                    <label>Fecha inicio (opcional):</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className='form-field-container'>
                    <label>Fecha final (opcional):</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className='form-field-container'>
                    <label>Registros (opcional):</label>
                    <input type="number" value={maxCount} onChange={(e) => setMaxCount(e.target.value)} />
                </div>
                <button type="submit">Buscar</button>
            </form>

            {error && <div className="error">{error}</div>}

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default UserLogsBase;
