import apiInstance from '../services/api';
import {transformDateTimeValues, localToUTC} from './dataTransforms'


const handleUnauthorized = (error, navigate, refreshAccessToken) => {
    if (error.response && (error.response.status === 401 || error.response.status === 422)) {   
        refreshAccessToken()
    } else if (error.response && (error.response.status === 404 )) {
        //navigate('/auth');
        throw error;
    }
    else {
        // Redirect the user to the login page
        
        throw error;  // If the error isn't a 401, re-throw it for further handling
    }
};

const handleResponse = async ( requestFunc, url = '', dateFields=[], params = null, navigate, refreshAccessToken) => {
    const headers = {
        'Content-Type': 'application/json'
    };
    try {
        const response = await requestFunc(url, { params, headers });
        return transformDateTimeValues(response.data, dateFields); // Adjust as needed
    } catch (error) {
        handleUnauthorized(error, navigate, refreshAccessToken);
    }
};


export const logUserAccess = async (navigate, refreshAccessToken) => {
    
    try {
        const response = await apiInstance.post(`/log_access`, 
            { access_type: 'login' }
        );
        return response.data;
    } catch (error) {
        handleUnauthorized(error, navigate, refreshAccessToken);
    }
};

export const changePassword = async (oldPassword, newPassword, confirmNewPassword, navigate, refreshAccessToken) => {
    const headers = {};
    const params = {
        "old_password": oldPassword,
        "new_password": newPassword,
        "confirm_new_password": confirmNewPassword
    };
    try {
        const response = await apiInstance.put(`/updatePassword`, 
            params,
            { headers: headers }
        );
        return response;
    } catch (error) {
        handleUnauthorized(error, navigate, refreshAccessToken);
    }
};

export const logPasswordChange = async ( changeType, navigate, refreshAccessToken) => {
    const headers = { };
    try {
        const response = await apiInstance.post(`/log_passwordChange`, 
            { change_type: changeType },
            { headers: headers }
        );
        return response.data;
    } catch (error) {
        handleUnauthorized(error, navigate, refreshAccessToken);
    }
};

export const logUserRegister = async () => {
    const headers = {
        'Content-Type': 'application/json'
    };
    try {
        const response = await apiInstance.post(`/log_access`, 
            { access_type: 'register' }, 
            { headers: headers }
        );
        return response.data;
    } catch (error) {
        handleUnauthorized(error);
    }
};

export const getUserInformation = async (username, navigate, refreshAccessToken) => {
    return handleResponse(apiInstance.get, `/user_information/${username.toLowerCase()}`,['date_joined', 'last_login'], null, navigate, refreshAccessToken);
};

export const getUserWelcomeInfo = async ( username, navigate, refreshAccessToken) => {
    
    return handleResponse( apiInstance.get, `/welcome_info/${username.toLowerCase()}`,['previous_login', 'last_utilization_date'], null, navigate, refreshAccessToken);
};

export const updateUserInfo = async ( data, navigate, refreshAccessToken) => {
    const headers = {};
    try {
        const response = await apiInstance.put(`/update-info`, 
            data,
            { headers: headers }
        );
        return response.data;
    } catch (error) {
        handleUnauthorized(error, navigate, refreshAccessToken);
    }
};

export const getUsers = async ( navigate, refreshAccessToken) => {
    
    return handleResponse(apiInstance.get, `/users`,['date_joined', 'last_login'], null, navigate, refreshAccessToken);
    
};

export const getUserCreditsBalance = async (username, navigate, refreshAccessToken) => {
    return handleResponse(apiInstance.get, `/user_credits_balance/${username.toLowerCase()}`,[], null, navigate, refreshAccessToken);
};

export const getAdmins = async (navigate, refreshAccessToken) => {
    return handleResponse( apiInstance.get, `/admins`,['date_joined', 'last_login'], null, navigate, refreshAccessToken);
};

export const getLogs = async ( navigate, refreshAccessToken) => {
    return handleResponse(apiInstance.get, `/users`,[], null, navigate, refreshAccessToken);
};

export const getUserLogs = async (username, startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse( apiInstance.get, `/get_access_logs/${username.toLowerCase()}`,['access_time'], params, navigate, refreshAccessToken);
};

export const getRecentLogs = async (startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_recent_logs`,['access_time'], params, navigate, refreshAccessToken);
};

export const getToolAccessLogs = async (username, startDate, endDate, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');

    return handleResponse( apiInstance.get, `/get_tool_access_logs/${username.toLowerCase()}`,[], params, navigate, refreshAccessToken);
};

export const getUserUtilizations = async (username, startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_utilizations/${username.toLowerCase()}`,['usage_date', 'finish_date'], params, navigate, refreshAccessToken);
};

export const getAllUtilizations = async ( startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_all_utilizations`,['used_date', 'finish_date'], params, navigate, refreshAccessToken);
};

export const getAllErrorLogs = async ( startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_all_error_logs`,['error_time'], params, navigate, refreshAccessToken);
};

export const getUserErrors = async (username, startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_user_errors/${username.toLowerCase()}`,['error_time'], params, navigate, refreshAccessToken);
};

export const getFeatureErrors = async (featureName, startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_tool_errors/${featureName}`,['error_time'], params, navigate, refreshAccessToken);
};

export const getAllTransactions = async ( startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_all_transactions`,['trans_date'], params, navigate, refreshAccessToken);
};

export const newTransaction = async ( data, navigate, refreshAccessToken) => {
    const headers = {};
    try {
        const response = await apiInstance.post(`/new_transaction`, 
            data,
            { headers: headers }
        );
        return response.data;
    } catch (error) {
        handleUnauthorized(error, navigate, refreshAccessToken);
    }
};

export const getFeatureUtilization = async (featureName, startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse(apiInstance.get, `/get_feature_utilization/${featureName}`,['used_date', 'finish_date'], params, navigate, refreshAccessToken);
};

export const getCreditRecords = async ( username, startDate, endDate, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');

    return handleResponse( apiInstance.get, `/user_credit_history/${username.toLowerCase()}`,['date'], params, navigate, refreshAccessToken);
};

export const getUserTransactions = async ( username, startDate, endDate, maxCount, navigate, refreshAccessToken) => {
    const params = {};
    if (startDate) params.start_date = localToUTC( startDate, 'startDate');
    if (endDate) params.end_date = localToUTC( endDate, 'endDate');
    if (maxCount) params.max_count = maxCount;

    return handleResponse( apiInstance.get, `/get_transactions/${username.toLowerCase()}`,['trans_date'], params, navigate, refreshAccessToken);
};

// ... Add other API call functions as needed
