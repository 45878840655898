//EnvConsultancy.jsx
import React from 'react';
import './EnvConsultancy.css';


function EnvConsultancy() {
    return (
        <div className='secondary-pages-view'>
            <div className="secondary-pages-container">
                <div className='section-header'>
                    CONSULTORÍA AMBIENTAL
                </div>
            </div>
        </div>
    );
}

export default EnvConsultancy;