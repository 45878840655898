import React, { useState, useEffect } from 'react'; // Add useContext import
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './RegisterForm.css';


function RegisterForm() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        first_name: '',
        last_name: '',
        email: '',
        cellphone: '',
        enterprise_name: '',
        acceptTerms: false,
        acceptPrivacyPolicy: false
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [loading, setLoading] = useState(false);


    // Function to generate reCAPTCHA token
    const generateRecaptchaToken = async () => {
        if (window.grecaptcha && window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(async () => {
                const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: 'register'});
                setRecaptchaToken(token);
            });
        }
    };

    // Generate token on component mount
    useEffect(() => {
        generateRecaptchaToken();
    }, []);

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        setMessage('');
        event.preventDefault();

        if (!formData.acceptTerms) {
            setMessage("Debes aceptar los términos y condiciones del servicio.");
            return;
        }

        if (!formData.acceptPrivacyPolicy) {
            setMessage("Debes aceptar la política de tratamiento de datos personales.");
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setMessage("Passwords do not match!");
            return;
        }

        setLoading(true); // Start loading

        // Refresh the reCAPTCHA token
        await generateRecaptchaToken();

        // Convert username and email to lowercase before submitting
        const payload = {
            ...formData,
            username: formData.username.toLowerCase(),
            email: formData.email.toLowerCase(),
            recaptchaToken
        };
        delete payload.confirmPassword;

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, payload, 
        { 
            withCredentials: true 
        })
        .then(response => {
            navigate('/auth/pending-verification');
        })
        .catch(error => {
            if (error.response && error.response.data.error) {
                setMessage(error.response.data.error);
            } else {
                setMessage("Unable to connect to the server. Please try again later.");
            }
        })
        .finally(() => {
            setLoading(false); // Stop loading when request is complete
        });
    };


    return (
        <div className="register-form-container">
            <h2>Registro</h2>
            {message && <div className="error-message">{message}</div>}
            {loading ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div>Procesando solicitud de registro.</div>
                    <div className="spinner"></div>
                </div>
            ) : (
                <form className='register-form' onSubmit={handleSubmit}>
                    <input 
                        className='register-input'
                        type="text" 
                        name="username"
                        placeholder="Nombre de usuario" 
                        value={formData.username}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="text" 
                        name="first_name"
                        placeholder="Nombres" 
                        value={formData.first_name}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="text" 
                        name="last_name"
                        placeholder="Apellidos" 
                        value={formData.last_name}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="email"
                        name="email"
                        placeholder="Email" 
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="tel"
                        name="cellphone"
                        placeholder="Número celular" 
                        value={formData.cellphone}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="text" 
                        name="enterprise_name"
                        placeholder="Empresa" 
                        value={formData.enterprise_name}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="password" 
                        name="password"
                        placeholder="Contraseña" 
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                    <input 
                        className='register-input'
                        type="password" 
                        name="confirmPassword"
                        placeholder="Confirmación Contraseña" 
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                    />
                    <div className="checkbox-container">
                        <input 
                            type="checkbox" 
                            name="acceptTerms"
                            checked={formData.acceptTerms}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="acceptTerms">Acepto los <a href="https://processmatica.com.co/support/terminos-uso-servicio" target="_blank" rel="noopener noreferrer"> Términos de uso del servicio</a></label>
                    </div>
                    <div className="checkbox-container">
                        <input 
                            type="checkbox" 
                            name="acceptPrivacyPolicy"
                            checked={formData.acceptPrivacyPolicy}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="acceptPrivacyPolicy">Acepto la <a href="https://processmatica.com.co/support/tratamiento-datos-personales" target="_blank" rel="noopener noreferrer"> política de tratamiento de datos personales</a></label>
                    </div>
                    <button className='register-button' type="submit">Registro</button>
                </form>
            )}
        </div>
    );
}

export default RegisterForm;
