//GISConsultancy.jsx
import React from 'react';
import './GISConsultancy.css';


function GISConsultancy() {
    return (
        <div className='secondary-pages-view'>
            <div className="secondary-pages-container">
                <div className='section-header'>
                    CONSULTORÍA SIG
                </div>
            </div>
        </div>
    );
}

export default GISConsultancy;