import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import { useAuth } from '../../context/AuthContext';

function Header() {
    const { logout, isAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [scrolled, setScrolled] = useState(false);

    const isLandingPage = location.pathname === "/";
    const fullSelectedLink = location.pathname;
    const selectedLink = fullSelectedLink.split("/")[1]

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 60) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleLogout(e) {
        e.preventDefault();
        logout();
        navigate('/');  
    }

    return (
        <div className={`headerContainer ${isLandingPage && !scrolled ? 'transparent' : 'solid'}`}>
            <Link to="/">
                <div className="logo-container"></div>
            </Link>
            <div className='menuContainer'>
                <div className={`header-link-div ${selectedLink === 'product-services' ? 'selected' : ''}`}>
                    <Link 
                        to="/product-services" 
                        className={`header-navLink ${selectedLink === 'product-services' ? 'selected' : ''}`} 
                    >
                        Productos & Servicios
                    </Link>
                </div>
                <div className={`header-link-div ${selectedLink === '/about-us' ? 'selected' : ''}`}>
                    <Link 
                        to="/about-us" 
                        className={`header-navLink ${selectedLink === '/about-us' ? 'selected' : ''}`}
                    >
                        Nosotros
                    </Link>
                </div>
                <div className={`header-link-div ${selectedLink === '/pricing' ? 'selected' : ''}`}>
                    <Link 
                        to="/pricing" 
                        className={`header-navLink ${selectedLink === '/pricing' ? 'selected' : ''}`} 
                    >
                        Precios
                    </Link>
                </div>
                <div className={`header-link-div ${selectedLink === 'downloads' ? 'selected' : ''}`}>
                    <Link 
                        to="/downloads" 
                        className={`header-navLink ${selectedLink === 'downloads' ? 'selected' : ''}`} 
                    >
                        Descargas
                    </Link>
                </div>
                { isAuth ? (
                    <>
                        <div className={`header-link-div ${selectedLink === '/profile' ? 'selected' : ''}`}>
                            <Link 
                                to="/profile" 
                                className={`header-navLink ${selectedLink === '/profile' ? 'selected' : ''}`} 
                            >
                                Mi Perfil
                            </Link>
                        </div>
                        <div className='header-link-div'>
                            <a href="/" onClick={handleLogout} className='header-navLink'>
                                Salir
                            </a>
                        </div>
                    </>
                ) : (
                    <div className={`header-link-div ${selectedLink === '/auth' ? 'selected' : ''}`}>
                        <Link 
                            to="/auth" 
                            className={`header-navLink ${selectedLink === '/auth' ? 'selected' : ''}`} 
                        >
                            Login / Registro
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Header;
