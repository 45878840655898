import React from 'react';
import './PricingView.css';


function PricingView() {
    return (
        <div className='secondary-pages-view'>
            <div className="secondary-pages-container">
                <div className='section-header'>
                    PRECIOS
                </div>
            </div>
        </div>
        
    );
}

export default PricingView;