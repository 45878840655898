import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import "./AuthView.css"

function AuthView() {
    const [showLogin, setShowLogin] = useState(true);
    return (
        <div className='secondary-pages-view'>
            <div className='auth-view-container'>
                <div className='auth-container'>
                    {showLogin ? 
                        <div className='login-container'>
                            <LoginForm /> 
                            <div className='reset-password-container'>
                                <Link className='reset-password-link' to="/auth/reset-password">¿Olvisate tu contraseña?</Link>
                            </div>
                            
                        </div>
                        
                    : 
                        <RegisterForm />}
                    <button className='register-button' onClick={() => setShowLogin(!showLogin)}>
                        {showLogin ? "Aún no tienes una cuenta? Registrate" : "Ya tienes una cuenta? Login"}
                    </button>
                </div>
                <div className='reCaptcha-container'>
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                    <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                </div>
                
            </div>
        </div>
        
    );
}

export default AuthView;
