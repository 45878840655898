import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './ChangePassword.css';
import { logPasswordChange, changePassword } from '../../helpers/api';


function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState('Testpassword*1');
    const [newpassword, setNewpassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {refreshAccessToken } = useAuth();

    const handleUpdate = async () => {
        try {
            const result = await changePassword( oldPassword, newpassword, confirmNewPassword, navigate, refreshAccessToken);
            if (result.status === 200){
            logPasswordChange('Password Change (web)', navigate, refreshAccessToken)
            setError("La contraseña fue actualizada con éxito.");
            // Reset fields
            setOldPassword('');
            setNewpassword('');
            setConfirmNewPassword('');
        } else {
            setError("No es posible actualizar la contraseña.");
        }
        } catch (err) {
            if (err.response && err.response.data.error) {
                setError(err.response.data.error);
            } else {
                setError("No es posible realizar la actualización.");
            }
        }
    };


    
    return (
        <div className="login-form-container-modal">
            <h3>Cambiar Contraseña</h3>
            {error && <div className="error-message">{error}</div>}
            <form className='login-form-modal'>
                <input 
                    className='login-input-modal'
                    type="text" 
                    placeholder="Contraseña actual" 
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <input 
                    className='login-input-modal'
                    type="password" 
                    placeholder="Nueva contraseña" 
                    value={newpassword}
                    onChange={(e) => setNewpassword(e.target.value)}
                />
                <input 
                    className='login-input-modal'
                    type="password" 
                    placeholder="Confirmación nueva contraseña" 
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
                <button onClick={(e)=> {e.preventDefault(); handleUpdate()}}
                    className='login-button' >
                    Cambiar contraseña
                </button>
            </form>
        </div>
    );
}

export default ChangePassword;
