import React from "react";

function ComboImageText(props) {
  function ComboImage(props) {
    return (
      <div
        className="comboImageText-image-container"
        style={{
          display: "flex",
          overflow: "hidden",
          position: "relative",
          width: `${props.ImageWidth}`,
          height: `${props.ImageHeight}`,
        }}
      >
        <img
          src={props.ImageUrl}
          alt="combo-text-img"
          className={props.ImageClassName}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={props.ComboImageTextWrapperClassName}
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.ImageLeft && <ComboImage {...props} />}
      <div
        className={
          props.ComboImageTextTextContentWrapper
            ? props.ComboImageTextTextContentWrapper
            : "comboImageText-text-content-wrapper"
        }
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div className={props.TextContenttitleClassName}>
          {props.TextContenttitle}
        </div>
        <div className={props.TextContentBodyClassName}>
          {props.TextContentBody}
        </div>
      </div>
      {!props.ImageLeft && <ComboImage {...props} />}
    </div>
  );
}

export default ComboImageText;
