import React, {  } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './UserSidebar.css';

function UserSidebar() {
    const location = useLocation();
    const selectedView = location.pathname;

    return (
        <div className="userSidebarContainer">
            <div className="sidebarLinksContainer">
                <div className={`sidebar-link-div ${selectedView === '/userview/profile' ? 'selected' : ''}`}>
                    <Link 
                        to="/userview/profile" 
                        className={`sidebarLink ${selectedView === '/userview/profile' ? 'selected' : ''}`} 
                    >
                        Mi Información de Perfil
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/userview/creditsBalance' ? 'selected' : ''}`}>
                    <Link 
                        to="/userview/creditsBalance" 
                        className={`sidebarLink ${selectedView === '/userview/creditsBalance' ? 'selected' : ''}`} 
                    >
                        Mis Créditos
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/userview/transactions' ? 'selected' : ''}`}>
                    <Link 
                        to="/userview/transactions" 
                        className={`sidebarLink ${selectedView === '/userview/transactions' ? 'selected' : ''}`} 
                    >
                        Mis Compras
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/userview/recent-logs' ? 'selected' : ''}`}>
                    <Link 
                        to="/userview/recent-logs" 
                        className={`sidebarLink ${selectedView === '/userview/recent-logs' ? 'selected' : ''}`} 
                    >
                        Mis Accesos Web
                    </Link>
                </div>
                <div className={`sidebar-link-div ${selectedView === '/userview/utilizationsBase' ? 'selected' : ''}`}>
                    <Link 
                        to="/userview/utilizationsBase" 
                        className={`sidebarLink ${selectedView === '/userview/utilizationsBase' ? 'selected' : ''}`} 
                    >
                        Mi Actividad
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/userview/user-errors' ? 'selected' : ''}`}>
                    <Link 
                        to="/userview/user-errors" 
                        className={`sidebarLink ${selectedView === '/userview/user-errors' ? 'selected' : ''}`} 
                    >
                        Mis registros de errores
                    </Link>
                </div>
                <br/>
                <div className={`sidebar-link-div ${selectedView === '/userview/support/' ? 'selected' : ''}`}>
                    <Link 
                        to="/userview/support/" 
                        className={`sidebarLink ${selectedView === '/userview/support/' ? 'selected' : ''}`} 
                    >
                        Soporte
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default UserSidebar;
