//AdminGetAllTransactions.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useTable } from 'react-table';
import { getAllTransactions } from '../../helpers/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { validateMaxCount } from '../../helpers/inputsValidation';

function AdminGetAllTransactions() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [maxCount, setMaxCount] = useState('20');
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const { refreshAccessToken, isAuth } = useAuth();


    const handleSubmit = useCallback(async (e) => {
        if (e) e.preventDefault();  // Check if event is passed to prevent default behavior
        //Input error handling
        if (!validateMaxCount(maxCount, setError,setTransactions)) { return; }
        //En of Input error handling
        if (isAuth){
            try {
                const result = await getAllTransactions( startDate, endDate, maxCount, navigate, refreshAccessToken);
                setTransactions(result);
                if (result.length === 0){
                    setError('No se encontraron transacciones.');
                    setTransactions(null);
                }else{
                    setError('');
                }
            } catch (err) {
                setError(err.message || 'Error fetching data.');
                setTransactions(null);
            }
        }
    }, [maxCount, isAuth, startDate, endDate, navigate, refreshAccessToken]);

    useEffect(() => {
        const fetchDataDueToTokenChange = async () => {
            if (isAuth) {
                //Input error handling
                if (!validateMaxCount(maxCount, setError,setTransactions)) { return; }
                //En of Input error handling
                try {
                    const result = await getAllTransactions(startDate, endDate, maxCount, navigate, refreshAccessToken);
                    setTransactions(result);
                    setError('');
                } catch (err) {
                    setError(err.message || 'Error fetching data.');
                    setTransactions(null);
                }
            }
        };
        fetchDataDueToTokenChange();
    }, [isAuth]);
    

    const dataFetched = React.useMemo(() => transactions, [transactions]);
    const data = (dataFetched)?(dataFetched):([])

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID Transacción',
                accessor: 'trans_id',
            },
            {
                Header: 'Fecha',
                accessor: 'trans_date',
            },
            {
                Header: 'Tipo',
                accessor: 'trans_type',
            },
            {
                Header: 'Créditos comprados',
                accessor: 'credits_purchased',
            },
            {
                Header: 'Valor',
                accessor: 'amount_paid',
            },
            {
                Header: 'Plataforma',
                accessor: 'trans_platform',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className='management-view'>
            <h2>Listado de transacciones</h2>
            
            <form className='form-container' onSubmit={handleSubmit}>
                <div className='form-field-container'>
                    <label>Fecha inicio (opcional):</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className='form-field-container'>
                    <label>Fecha final (opcional):</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className='form-field-container'>
                    <label>Registros (opcional):</label>
                    <input type="number" value={maxCount} onChange={(e) => setMaxCount(e.target.value)} />
                </div>
                <button type="submit">Buscar</button>
            </form>

            {error && <div className="error">{error}</div>}

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default AdminGetAllTransactions;