import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import instagramIco from '../../assets/images/Instagram_icon.png'
import linkedInIco from '../../assets/images/LinkedIn_logo_initials.png'
import tiktokIco from '../../assets/images/tiktok-logo.png'

function Footer() {

    return (
        <footer className= 'footer'>
            <div className= 'footerNavContainer' >
                <div className= 'footerNavDiv'>
                    <Link  to="/support/contactUs" className= 'footerNavLink' >
                        Conctáctanos
                    </Link>
                </div>
                <div className= 'footerNavDiv'>
                    <Link  to="/support/support" className= 'footerNavLink' >
                        Soporte
                    </Link>
                </div>
                <div className= 'socialContainer'>
                    <div className= 'socialTittle'>Nuestras redes sociales</div>
                    <div className= 'footerNavLink'>
                        <div className='social-wrapper'>
                            <a href='https://www.instagram.com/processmatica'><img src={instagramIco} alt='Instagram' width="20" height="20" /></a>
                        </div>
                    </div>
                    <div className= 'footerNavLink'>
                        <div className='social-wrapper'>
                            <a href='https://www.linkedin.com/processmatica'><img src={linkedInIco} alt='LinkedIn' width="20" height="20" /></a>
                        </div>
                    </div>
                    <div className= 'footerNavLink'>
                        <div className='social-wrapper'>
                            <a href='https://www.tiktok.com/processmatica'><img src={tiktokIco} alt='TikTok' width="20" height="20" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <p>&copy; 2023 ProcessMática. All rights reserved.</p>
        </footer>
    );
}

export default Footer;
