import React from 'react';

function ProductInfoCard(props) {

    const renderIcon = () => {
        return (
            <div 
                className='productInfoCard-icon-container'
                style={{
                    display: 'flex',
                    overflow: 'hidden',
                    position: 'relative',
                    width: props.IconWidth,
                    height: props.IconHeight
                }}>
                <img 
                    src={props.IconUrl}  
                    alt='product-icon' 
                    className={props.IconClassName}
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }} 
                />
            </div>
        );
    };

    return (
        <div 
            id= {props.id}
            className={props.ProductInfoCardWrapperClassName} 
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <div className={props.TitleClassName}>
                {props.TitleText}
            </div>
            <div className={props.DescriptionClassName}>
                {props.DescriptionText}
            </div>
            <div className={props.IcontContainerClassName}>
                {renderIcon()}
            </div>
            
        </div>
    );
}

export default ProductInfoCard;
