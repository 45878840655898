// inputsValidation.js

// Username validation
export const validateUsername = (value, setError, setResult) => {
    const regex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!value) {
        setError("Debe especificar un usuario.");
        setResult(null);
        return false;
    } else if (!regex.test(value)) {
        setError("Formato inválido para Usuario. Debe ser alfanumérico de 3 - 20 caracteres.");
        setResult(null);
        return false;
    }
    return true;  // validation passed
};

// Feature validation
export const validateFeature = (value, setError, setResult) => {
    const regex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!value) {
        setError("Debe especificar una herramienta.");
        setResult(null);
        return false;
    } else if (!regex.test(value)) {
        setError("Formato inválido para Herramienta.");
        setResult(null);
        return false;
    }
    return true;  // validation passed
};

// MaxCount validation
export const validateMaxCount = (value, setError, setResult) => {
    // If value is null or empty, it's valid
    if (value === null || value === '') {
        return true;
    }
    // Check if value is a positive integer
    const intValue = parseInt(value, 10);
    if (intValue <= 0 || intValue.toString() !== value) {
        setError("El valor de 'Registros' debe ser un número mayor a cero.");
        setResult(null);
        return false;
    }

    return true;  // validation passed
};

// Other validation functions can be added below...
