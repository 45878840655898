import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserWelcomeInfo } from '../../helpers/api';
import { useAuth } from '../../context/AuthContext';

function UserWelcomeBase() {
    const { currentUser, refreshAccessToken, logout } = useAuth();
    const [creditsBalance, setCreditsBalance] = useState('')
    const [pendingCredits, setPendingCredits] = useState('')
    const [previousAccess, setPreviousAccess] = useState('')
    const [lastUtilization, setLastUtilization] = useState('')
    const [lastUtilizationDate, setLastUtilizationDate] = useState('')
    const [totalUtuilizations, setTotalUtilizations] = useState('')
    const [username, setUsername] = useState('')
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            setUsername(currentUser.username);
        } else {
            refreshAccessToken();
        }
    }, [currentUser, refreshAccessToken]);
    

    const getWelcomeInfo = useCallback(async (e) => {
        if (username){
            try {
                const result = await getUserWelcomeInfo(username, navigate, refreshAccessToken);
                setCreditsBalance(result[0].credits_balance);
                setPendingCredits(result[0].pending_credits);
                setPreviousAccess(result[0].previous_login);
                setLastUtilization(result[0].last_utilization);
                setLastUtilizationDate(result[0].last_utilization_date);
                setTotalUtilizations(result[0].total_utilizations);
                setError('');
            } catch (err) {
                console.log('error_UW_func: ', err);
                // Check if err.response exists before accessing its properties
                if (err.response) {
                    if (err.response.status === 404 || err.response.status === 400) {
                        setError('Usuario no encontrado.');
                    } else {
                        setError('Error fetching data.');
                    }
                } else {
                    // Handle cases where err.response is undefined
                    // This could be a network error, CORS issue, etc.
                    setError('Network error or CORS issue.');
                }
            }
        }
        
    }, [username, navigate, refreshAccessToken]);
    
 
   
    useEffect(() => {
        getWelcomeInfo();
    }, [getWelcomeInfo]); 

    return (
        <div className="welcome-container">
            <div className="welcome-header-container">
                <h2>Bienvenido de nuevo</h2><h1>{username}</h1>
            </div>
            <div className='welcome-message-container'>
                <div className='welcome-message-title'>Nos encanta verte de nuevo.</div>
                <div className='welcome-message-text'>Este es el resumen de tu actividad más reciente.</div>
            </div>
            {error && <><br /><div className="error">{error}</div></>}
            <div className="welcome-summary-container">
                <div className='welcome-simple-data-container'>
                    <div className='welcome-simple-data-label'>Tu balance de créditos: </div>
                    <div className='welcome-simple-data-value'>{creditsBalance}</div>
                </div>
                <div className='welcome-simple-data-container'>
                    <div className='welcome-simple-data-label'>Tus créditos pendientes: </div>
                    <div className='welcome-simple-data-value'>{pendingCredits}</div>
                </div>
                <div className='welcome-simple-data-container'>
                    <div className='welcome-simple-data-label'>Tu último acceso: </div>
                    <div className='welcome-simple-data-value'>{previousAccess}</div>
                </div>
                <div className='welcome-simple-data-container'>
                    <div className='welcome-simple-data-label'>Tu última utilización: </div>
                    <div className='welcome-simple-data-value'>{lastUtilization}</div>
                </div>
                <div className='welcome-simple-data-container'>
                    <div className='welcome-simple-data-label'>Fecha de tu última utilización: </div>
                    <div className='welcome-simple-data-value'>{lastUtilizationDate}</div>
                </div>
                <div className='welcome-simple-data-container'>
                    <div className='welcome-simple-data-label'>Total utilizaciones: </div>
                    <div className='welcome-simple-data-value'>{totalUtuilizations}</div>
                </div>
            </div> 
            <div className='bottom-message-container'>
                <div className='bottom-message-title'>Recuerda que en la barra lateral encuentras más opciones de información.</div>
                <div className='bottom-message-text'></div>
            </div>
        </div>
        
    );
}

export default UserWelcomeBase;