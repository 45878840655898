//DownloadsView.jsx
import React from 'react';
import { useEffect } from 'react';
import { useLocation  } from 'react-router-dom';
import './DownloadsView.css'
import DownloadsBG from '../../assets/images/products-services-bg.png';

function useAnchorScroll() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const anchorElement = document.querySelector(location.hash);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]); // Notice the dependency array now includes location
}

function DownloadsView() {
    
    useAnchorScroll();

    return (
        <div className='secondary-pages-view'>
            <div 
            className="secondary-pages-container" 
            style={{ 
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${DownloadsBG})`,
                height: 'calc(100vh - var(--header-footer-sum-height) )'
                }}>
                <div className='section-header'>
                    DESCARGAS
                </div>
                <div className='secondary-page-main-body'>
                    <div className= 'register-message'>
                        Recuerda que para utilizar las herramientas debes de registrarte.
                    </div>
                    <div className='downloads-tool-wrapper'>
                        <div className='downloads-product-container'>
                            <div className='downloads-product-title' id='GDBAudit'>
                                GDB Audit
                            </div>
                            <div className='downloads-tool-container'>
                                <div className='downloads-tool-name'>
                                    Toolbox Revisión GDB (Para ArcGIS Desktop):
                                </div>
                                <a className='downloads-tool-link' href="/downloads/GDB_Audit_Toolbox_V1_02.tbx" download>
                                    GDB_Audit_Toolbox_V1_02.tbx
                                </a>
                            </div>
                            <div className='downloads-tool-container'>
                                <div className='downloads-tool-name'>
                                    Toolbox Registro de computador (Para ArcGIS Desktop):
                                </div>
                                <a className='downloads-tool-link' href="/downloads/GDB_Audit_RegistroMaquina_Toolbox_V1_00.tbx" download>
                                    GDB_Audit_RegistroMaquina_Toolbox_V1_00.tbx
                                </a>
                            </div>
                            <div className='downloads-tool-container'>
                                <div className='downloads-tool-name'>
                                    Toolbox Cálculo Score y Uso créditos (Para ArcGIS Desktop):
                                </div>
                                <a className='downloads-tool-link' href="/downloads/GDB_Audit_Calculadora_Score_Toolbox_V1_00.tbx" download>
                                    GDB_Audit_Calculadora_Score_Toolbox_V1_00.tbx
                                </a>
                            </div>
                        </div>
                        
                    
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default DownloadsView;