//PoliticaDatos.jsx
import React from "react";
import "./PoliticaDatos.css";

function PoliticaDatos() {
  return (
    <div className="secondary-pages-view">
      <div className="secondary-pages-container">
        <div className="section-header">
          Política de Tratamiento de Datos Personales (Política de Privacidad)
        </div>
        <div className="secondary-page-main-body">
          <h2 class="entry-title">ProcessMática S.A.S </h2>
          <p>
            <strong>I. INTRODUCCIÓN</strong>
          </p>
          <p>
            <strong> ProcessMática S.A.S. </strong>es una compañía colombiana
            dedicada a la prestación de servicios SIG y
          </p>
          <p>
            <strong> ProcessMática S.A.S. </strong>, con la finalidad de dar
            cumplimiento estricto a la normatividad vigente de protección de
            Datos Personales, de acuerdo a lo establecido en la Ley 1581 del
            2012, Decreto 1074 de 2015 y demás disposiciones que las modifiquen,
            adicionen o complementen presenta la siguiente
            <strong>
              POLÍTICA DE PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES
            </strong>
            (en adelante “Política de Tratamiento”) con el propósito de proteger
            la información personal brindada por los Titulares que tengan
            relación con<strong> ProcessMática S.A.S. </strong>como lo son
            socios, proveedores, clientes, empleados, colaboradores y cualquier
            otra persona natural de la cual
            <strong> ProcessMática S.A.S. </strong>, obtenga, recolecte, procese
            o trate datos personales, sea que dicho tratamiento sea realizado
            por<strong> ProcessMática S.A.S. </strong>o por terceras personas
            que lo hagan por encargo de ésta.
          </p>
          <p>
            La Política de Tratamiento tiene como objeto proteger el derecho
            constitucional del Habeas Data que tienen todas las personas para
            conocer, actualizar, y rectificar la información que se haya
            recogido y almacenado en las distintas bases de datos de
            <strong> ProcessMática S.A.S.,</strong>y en virtud del cumplimiento
            de dicho derecho sólo recolecta y da Tratamiento a Datos Personales,
            cuando así haya sido autorizado previamente por su Titular,
            implementando para tal efecto, medidas claras sobre confidencialidad
            y privacidad de los Datos Personales. Así mismo, detalla los
            lineamientos generales corporativos que se tienen en cuenta con el
            fin de proteger los Datos Personales de los Titulares, las
            finalidades de Tratamiento de la información, el área responsable de
            atender las quejas y reclamos, y los procedimientos que se deben
            agotar para conocer actualizar, rectificar y suprimir la información
            y los respectivos canales para que estos puedan ejercerlos.
          </p>
          <p>
            <strong>II. DEFINICIONES</strong>
          </p>
          <p>
            <strong>API</strong>: Significa la interfaz de programación de
            aplicaciones que se realizará entre los sistemas de terceros y el de
            ProcessMática S.A.S.
          </p>
          <p>
            <strong>Autorización:</strong>Consentimiento previo, expreso e
            informado del Titular para llevar a cabo el tratamiento de datos
            personales.
          </p>
          <p>
            <strong>Aviso de privacidad:</strong>Comunicación verbal o escrita
            dirigida a los Titulares de los datos personales que están siendo
            tratados por la empresa, en la cual se le informa acerca de la
            existencia de las políticas de tratamiento de datos personales que
            le serán aplicadas, la forma de acceder a la mismas, y las
            finalidades para las cuales serán usados sus datos personales.
          </p>
          <p>
            <strong>Base de datos personales:</strong>Conjunto organizado de
            datos personales que son objeto de tratamiento por una persona
            natural o jurídica.
          </p>
          <p>
            <strong>Custodio de las bases de datos:</strong>Persona natural,
            dentro de la empresa, que custodia las bases de datos personales.
          </p>
          <p>
            <strong>Dato personal:</strong>Cualquier información concerniente o
            vinculada a personas naturales determinadas o determinables.
          </p>
          <p>
            <strong>Dato sensible:</strong>Es aquel dato personal que afecta la
            intimidad del Titular y cuyo uso incorrecto podría generar
            discriminación. Son considerados datos sensibles entre otros, los
            datos de salud, los datos de orientación sexual, origen racial y
            étnico, opiniones políticas, convicciones religiosas, filosóficas o
            morales.
          </p>
          <p>
            <strong>Dato privado:</strong>Es aquel dato personal que por su
            carácter íntimo o reservado es relevante para el Titular.
          </p>
          <p>
            <strong>Dato semiprivado:</strong>Es aquel dato personal conocido y
            de interés tanto para el titular como para un determinado sector de
            personas o para la sociedad en general, por lo que no es de carácter
            íntimo, reservado o público.
          </p>
          <p>
            <strong>Dato público:</strong>Es aquel dato personal calificado como
            tal según la Constitución y la ley, y que no se ha clasificado como
            dato personal privado o semiprivado.
          </p>
          <p>
            <strong>Encargado del tratamiento:</strong>Persona natural o
            jurídica, de carácter público o privado que por sí misma o en asocio
            con otros, realiza el tratamiento de datos personales por cuenta del
            responsable.
          </p>
          <p>
            <strong>Formas de recabar los datos personales:</strong>
            <strong> ProcessMática S.A.S. </strong>podrá conocer, recolectar,
            almacenar, administrar la información del titular de la información
            de conformidad con la política de uso de datos contenida en el
            presente documento a través de los siguientes medios: (i)
            Inscripción y uso de la página web de
            <strong> ProcessMática S.A.S. </strong>; (ii) suscripción de
            cualquier tipo de contrato, alianza y/o convenio con
            <strong> ProcessMática S.A.S. </strong>; (iii) Inscripción como
            proveedor de<strong> ProcessMática S.A.S. </strong>
          </p>
          <p>
            <strong>Habeas data:</strong>Es el derecho que tiene el Titular de
            los datos personales de exigir de las administradoras de los mismos
            el acceso, inclusión, exclusión, corrección, adición, actualización
            y rectificación de los datos, así como la limitación en su
            divulgación, publicación o cesión.
          </p>
          <p>
            <strong>Responsable del tratamiento:</strong>Persona natural o
            jurídica de carácter público o privado que por sí misma o en asocio
            con otro u otros decide sobre el tratamiento de datos personales.
          </p>
          <p>
            <strong>Titular del dato personal:</strong>Persona natural cuyos
            datos son objeto de tratamiento. En el contexto de la presente
            política de tratamiento de datos personales los titulares podrán
            ser: (i) suscriptores/Clientes de la plataforma; (ii) Contratistas;
            (iii) proveedores se haga tratamiento de los datos personales.
          </p>
          <p>
            <strong>Tratamiento:</strong>Cualquier operación o conjunto de
            operaciones sobre datos personales, tales como la recolección,
            almacenamiento, uso, circulación o supresión.
          </p>
          <p>
            <strong>Transmisión:</strong>Tratamiento de Datos Personales que
            implica la comunicación a un tercero de los mismos, dentro o fuera
            del territorio de la República de Colombia, cuando dicha
            comunicación tenga por objeto la realización de un Tratamiento por
            el Encargado en nombre y por cuenta del Responsable, para cumplir
            con las finalidades de este último.
          </p>
          <p>
            <strong>Transferencia:</strong>La Transferencia de Datos Personales
            tiene lugar cuando el Responsable y/o Encargado del Tratamiento de
            Datos Personales envía la información o los datos personales a un
            receptor, que a su vez es responsable del tratamiento y se encuentra
            dentro o fuera del país.
          </p>
          <p>
            <strong>Violación de datos personales:</strong>Es el delito
            tipificado en el artículo 269 del Código Penal. El cual establece: “
            <em>
              El que, sin estar facultado para ello, con provecho propio o de un
              tercero, obtenga, compile, sustraiga, ofrezca, venda, intercambie,
              envíe, compre, intercepte, divulgue, modifique o emplee códigos
              personales, datos personales contenidos en ficheros, archivos,
              bases de datos o medios semejantes, incurrirá en pena de prisión
              de cuarenta y ocho (48) a noventa y seis (96) meses y en multa de
              100 a 1000 salarios mínimos legales mensuales vigentes.”
            </em>
          </p>
          <p>
            <strong>
              III. PRINCIPIOS RECTORES PARA EL TRATAMIENTO DE DATOS PERSONALES
            </strong>
          </p>
          <p>
            Según lo establecido en el Título II de la Ley Estatutaria 1581 de
            2012, la protección de datos personales se regirá por la aplicación
            armónica e integral de los siguientes principios:
          </p>
          <p>
            <strong>
              Principio de legalidad en el tratamiento de datos personales:
            </strong>
            El tratamiento de datos personales a que se refiere la Ley
            Estatutaria 1581 de 2012 es una actividad reglada que debe sujetarse
            a lo establecido en ella y en las demás disposiciones que la
            desarrollen.
          </p>
          <p>
            <strong>Principio de finalidad:</strong>El tratamiento de los datos
            personales debe obedecer a una finalidad legítima de acuerdo con la
            Constitución y la ley, la cual debe ser informada al Titular.
          </p>
          <p>
            <strong>Principio de libertad:</strong>El tratamiento de los datos
            personales sólo puede ejercerse con el consentimiento, previo,
            expreso e informado del Titular. Los datos personales no podrán ser
            obtenidos o divulgados sin previa autorización, o en ausencia de
            mandato legal o judicial que releve el consentimiento.
          </p>
          <p>
            <strong>Principio de veracidad o calidad:</strong>La información
            sujeta a tratamiento debe ser veraz, completa, exacta, actualizada,
            comprobable y comprensible. Se prohíbe el Tratamiento de datos
            parciales, incompletos, fraccionados o que induzcan a error.
          </p>
          <p>
            <strong>Principio de transparencia:</strong>En el Tratamiento de los
            datos personales debe garantizarse el derecho del Titular a obtener
            del Responsable del tratamiento o del Encargado del tratamiento, en
            cualquier momento y sin restricciones, información acerca de la
            existencia de datos que le conciernan.
          </p>
          <p>
            <strong>Principio de seguridad:</strong>La información sujeta a
            tratamiento por el Responsable del Tratamiento o Encargado del
            tratamiento a que se refiere la Ley Estatutaria 1581 de 2012, se
            deberá manejar con las medidas técnicas, humanas y administrativas
            que sean necesarias para otorgar seguridad a los registros evitando
            su adulteración, pérdida, consulta, uso o acceso no autorizado o
            fraudulento.
          </p>
          <p>
            <strong>Principio de confidencialidad:</strong>Todas las personas
            que intervengan en el tratamiento de datos personales que no tengan
            la naturaleza de públicos están obligadas a garantizar la reserva de
            la información, inclusive después de finalizada su relación con
            alguna de las labores que comprende el tratamiento, pudiendo sólo
            realizar suministro o comunicación de datos personales cuando ello
            corresponda al desarrollo de las actividades autorizadas en la Ley
            Estatutaria 1581 de 2012 y en los términos de la misma.
          </p>
          <p>
            <strong>Principio de acceso y circulación restringida:</strong>
            El tratamiento se sujeta a los límites que se derivan de la
            naturaleza de los datos personales, de las disposiciones de la Ley
            Estatutaria 1581 de 2012 y la Constitución. En este sentido, el
            tratamiento sólo podrá hacerse por personas autorizadas por el
            Titular y/o por las personas previstas en la mencionada ley.
          </p>
          <p>
            <strong>IV. DERECHOS DE LOS TITULARES</strong>
          </p>
          <p>
            En cumplimiento de las garantías fundamentales consagradas en la
            Constitución y la ley, y sin perjuicio de lo dispuesto en las demás
            normas que regulen la materia, los Titulares de los datos personales
            podrán ejercer de forma gratuita e ilimitadamente los siguientes
            derechos:
          </p>
          <p>
            <strong>Derecho al acceso</strong>a su información personal objeto
            de tratamiento.
          </p>
          <p>
            <strong>Derecho a la actualización</strong>de los datos personales
            objeto de tratamiento.
          </p>
          <p>
            <strong>Derecho a la rectificación</strong>de los datos personales
            objeto de tratamiento.
          </p>
          <p>
            <strong>Derecho de oposición</strong>a que los datos personales sean
            objeto de tratamiento
          </p>
          <p>
            <strong>Derecho a solicitar la supresión</strong>de los datos
            personales cuando en el tratamiento no se respeten los principios,
            derechos y garantías constitucionales y legales.
          </p>
          <p>
            <strong>Derecho a solicitar prueba</strong>de la autorización
            otorgada para el tratamiento.
          </p>
          <p>
            <strong>Derecho a revocar el consentimiento</strong>para el
            tratamiento de los datos personales.
          </p>
          <p>
            <strong>Derecho a presentar quejas y reclamos</strong>ante la
            Superintendencia de Industria y Comercio por infracciones a lo
            dispuesto en la Ley Estatutaria 1581 de 2012 y las demás normas que
            la modifiquen, adicionen o complementen.
          </p>
          <p>
            <strong>Derecho a ser informado</strong>por parte del Responsable
            y/o Encargado del uso y tratamiento que se les dará a los datos
            personales, así como de las modificaciones y actualizaciones de las
            políticas de protección, medidas de seguridad y finalidades.
          </p>
          <p>
            <strong>V. FORMA DE RECOLECTAR LOS DATOS</strong>
          </p>
          <p>
            <strong>
              Forma de recolección de los datos personales de los
              Usuarios/Clientes:
            </strong>
          </p>
          <p>
            La recolección de datos personales de usuarios potenciales y
            usuarios de<strong> ProcessMática S.A.S. </strong>, se realizará de
            las siguientes formas:
          </p>
          <ul>
            <li>
              Mediante el almacenamiento automático de los datos de los usuarios
              que acceden a la plataforma de
              <strong> ProcessMática S.A.S. </strong>por el uso de cookies.
              Algunos de los datos que se pueden almacenar automáticamente son
              las URL, el navegador utilizado, dirección IP entre otros.
            </li>
            <li>Mediante el intercambio de correos electrónicos.</li>
            <li>A través del acceso a páginas Web de la compañía.</li>
            <li>
              A través del acceso a la aplicación, creando el usuario y
              contraseña.
            </li>
            <li>A través de llamadas telefónicas.</li>
          </ul>
          <p>
            <strong>
              Forma de recolectar los datos de los Trabajadores o candidatos
            </strong>
          </p>
          <p>
            La recolección de datos personales de empleados se realizará de las
            siguientes formas:
          </p>
          <ul>
            <li>A través de las hojas de vida académicas de los candidatos.</li>
            <li>
              A través del contrato de trabajo o de prestación de servicios.
            </li>
            <li>A través de las hojas de vida académicas de los empleados.</li>
            <li>
              A través del formato de ingreso a
              <strong> ProcessMática S.A.S. </strong>o formato de solicitud de
              empleo.
            </li>
            <li>
              Mediante el uso de listas de entrada de los empleados a las
              instalaciones de la empresa.
            </li>
            <li>En el desarrollo de entrevistas de trabajo.</li>
            <li>
              Mediante envío de información de empresas que tenga la calidad de
              caza talentos y los titulares hayan suministrado los datos.
            </li>
            <li>Mediante formatos de afiliación a la EPS, ARLs.</li>
            <li>
              Por medio de afiliación a las plataformas web ofrecidas por
              <strong> ProcessMática S.A.S. </strong>
            </li>
          </ul>

          <p>
            <strong>Forma de recolección de Proveedores</strong>
          </p>
          <p>
            La recolección de datos personales de los proveedores se realizará
            de las siguientes formas:
          </p>
          <ul>
            <li>A través de ofertas de servicios.</li>
            <li>A través del intercambio de correos electrónicos.</li>
            <li>A través del contrato de cooperación.</li>
            <li>A través del contrato de prestación de servicios.</li>
            <li>A través portafolios de servicios.</li>
            <li>A través de reuniones o comunicaciones telefónicas.</li>
          </ul>
          <p>
            <strong>Forma de recolección de Socios</strong>
          </p>
          <p>
            <strong>Recolección de los datos personales de socios</strong>
          </p>
          <p>
            La recolección de datos personales de los socios se realizará de las
            siguientes formas:
          </p>
          <ul>
            <li>A través de la suscripción de acciones.</li>
            <li>
              Mediante reuniones de órganos directivos o asambleas generales de
              accionistas y sus respectivas convocatorias.
            </li>
            <li>
              A través del intercambio de correos electrónicos u otros medios de
              comunicación con los accionistas.
            </li>
          </ul>
          <p>
            <strong>
              VI. FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES
            </strong>
          </p>
          <p>
            Los Datos Personales que recolecta
            <strong> ProcessMática S.A.S. </strong>, son incluidos en una Base
            de Datos a la cual tiene acceso el personal autorizado de
            <strong> ProcessMática S.A.S. </strong>en ejercicio de sus
            funciones, advirtiendo que en ningún caso está autorizado el
            Tratamiento de la información para fines diferentes a los aquí
            descritos, y que le sean comunicados al Titular directamente a más
            tardar al momento de la recolección.
          </p>
          <p>
            <strong>
              Finalidad de tratamiento de los datos personales de los Usuarios /
              Clientes
            </strong>
          </p>
          <ul>
            <li>
              La recolección, almacenamiento, uso y/o circulación de los datos
              personales de usuarios de
              <strong> ProcessMática S.A.S. </strong>tiene como finalidad
              principal prestar los servicios ofrecidos y/o contratados de forma
              adecuada y con una excelente calidad. Por lo anterior, las
              finalidades de recolección y tratamiento de los Datos Personales
              de los Usuarios y Clientes de
              <strong> ProcessMática S.A.S. </strong>serán las
              siguientes:Ordenar, catalogar, clasificar, dividir o separar y
              almacenar los datos personales dentro de los sistemas y archivos
              de
              <strong>ProcessMática S.A.S</strong>
            </li>
            <li>Creación y administración de la cuenta del usuario.</li>
            <li>
              Prestar el mantenimiento, desarrollo y/o control de la relación
              comercial entre el Titular del dato personal y
              <strong> ProcessMática S.A.S. </strong>
            </li>
            <li>
              Proveer a los usuarios de la información necesaria, a través de la
              página Web y aplicación, sobre los productos de los oferentes,
              para formalizar la relación de consumo de dichos productos.
            </li>
            <li>
              Realizar procesos al interior de la empresa, con fines de
              desarrollo operativo y/o de administración de sistemas.
            </li>
            <li>
              Prestar los servicios de la empresa y realizar el seguimiento de
              acuerdo con las necesidades particulares del usuario, con el fin
              de brindar los servicios y productos adecuados para cubrir sus
              necesidades específicas.
            </li>
            <li>
              Realizar el envío de información de novedades, noticias,
              boletines, foros de educación, publicidad o marketing, ventas a
              distancia. Haciendo uso de medios tales como, correo electrónico,
              notificaciones PUSH, mensajes de texto (SMS), ofertas de productos
              y/o servicios encontrados en la página web y la aplicación.
            </li>
            <li>
              Llevar un registro histórico de la información, con fines de
              satisfacción al usuario desarrollando análisis sobre los intereses
              y necesidades; brindando de esta manera un mejor servicio.
            </li>
            <li>
              Realizar estrategias de mercado mediante el estudio del
              comportamiento del usuario frente a las ofertas y con ello mejorar
              en su contenido, personalizando presentación y servicio.
            </li>
            <li>
              Elaboración de prospecciones comerciales y segmentación de
              mercados.
            </li>
            <li>
              Realizar encuestas de satisfacción y ofrecimiento o reconocimiento
              de beneficios propios de nuestro programa de lealtad y servicio
              postventa, para calificar el servicio y la atención por medio de
              los canales dispuestos para ello.
            </li>
            <li>
              Adelantar las actividades necesarias para gestionar las
              solicitudes, quejas y reclamos de los usuarios de la empresa o
              tercero; y direccionarlos a las áreas responsables de emitir las
              respuestas correspondientes.
            </li>
            <li>
              Presentar reportes ante las autoridades de inspección, vigilancia
              y control, y tramitar los requerimientos realizados por entidades
              administrativas o judiciales.
            </li>
            <li>
              Usos administrativos, comerciales y de publicidad que se
              establezcan en los acuerdos suscritos con los clientes Titulares
              de la información.
            </li>
            <li>
              Gestión contable, económica, fiscal y administrativa de clientes.
            </li>
            <li>
              Tener acceso a centrales de riesgo para conocer los estados
              financieros de los clientes.
            </li>
            <li>
              Conservación de la información por los términos establecidos en la
              Ley, especialmente el referente a la información de los libros y
              papeles del comerciante que deberá ser almacenada por un período
              de diez (10) años, según lo dispone el artículo 28 de la Ley 962
              de 2005.
            </li>
            <li>
              Remitir información a los Encargados del Tratamiento para
              facilitar y mejorar la calidad del servicio de
              <strong> ProcessMática S.A.S. </strong>
            </li>
            <li>
              Reportes a centrales de riesgo por incumplimiento de las
              obligaciones financieras derivadas de la relación comercial.
            </li>
            <li>
              Solicitar la autorización de cobro ante las entidades definidas y
              autorizadas para ello.
            </li>
          </ul>
          <p>
            De llegarse a presentar otro tipo de finalidades en el tratamiento
            de datos personales se solicitará la autorización previa, expresa e
            informada del Titular.
          </p>
          <p>
            <strong>
              Finalidad de tratamiento de los datos de los trabajadores y
              candidatos
            </strong>
          </p>
          <p>
            Antes de iniciar la relación laboral,
            <strong> ProcessMática S.A.S. </strong>informará a los candidatos en
            los procesos de selección las finalidades del tratamiento que se les
            dará a los datos personales que suministren en dicho proceso, y se
            encargará de solicitar la correspondiente autorización para su
            tratamiento, que se limitará a:
          </p>
          <ul>
            <li>
              Clasificar, almacenar y archivar los datos personales de los
              candidatos de los procesos de selección.
            </li>
            <li>
              Verificar y obtener referencias de personas naturales o jurídicas,
              antiguos empleadores suministradas por los candidatos en hojas de
              vida, formularios, entre otros.
            </li>
            <li>
              Verificar, comparar y evaluar las competencias laborales y
              personalesde los prospectos respecto de los criterios de
              selección.
            </li>
            <li>
              Cumplir con los deberes legales a los que está obligada la
              empresa.
            </li>
            <li>Envío ofertas de trabajo.</li>
          </ul>
          <p>
            La recolección y tratamiento de los datos personales de los
            empleados se hará con el fin de desarrollar las actividades pactadas
            entre
            <strong> ProcessMática S.A.S. </strong>y sus trabajadores, así como
            para llevar a cabo las siguientes actividades:
          </p>
          <ul>
            <li>
              Realizar mantenimiento, desarrollo y/o control de la relación
              laboral entre el Titular del dato y
              <strong> ProcessMática S.A.S. </strong>
            </li>
            <li>
              Adelantar procesos al interior de la empresa, con fines de
              desarrollo operativo y/o de administración de sistemas.
            </li>
            <li>
              Realizar registros de gestión económica, contable, fiscal y
              administrativa, para la gestión de cobros y pagos, de facturación
              y de cumplimiento de obligaciones financieras.
            </li>
            <li>
              Realizar declaraciones tributarias o gestión de información
              tributaria y de recaudo.
            </li>
            <li>Contacto de un acudiente en caso de emergencia.</li>
            <li>Asegurar a los empleados al Sistema de Seguridad Social.</li>
            <li>
              Realizar la gestión de recursos humanos en temas relacionados con
              la formación, trabajo temporal, prestaciones sociales, prevención
              de riesgos laborales, promoción y gestión del empleo, selección de
              personal, control de horario y, en general, asuntos relacionados
              con nómina.
            </li>
            <li>
              Realizar la gestión relacionada con las prestaciones sociales
              laborales, pensiones, subsidios y otras prestaciones económicas
            </li>
            <li>
              Solicitar información sobre cuentas de crédito, depósito,
              cumplimiento de obligaciones financieras y gestión de fondos
              pensionales para el acatamiento de las obligaciones a que haya
              lugar y el normal desarrollo de la relación laboral.
            </li>
            <li>
              Tramitar la elaboración de certificaciones relativas a su
              condición de empleado tales como certificados de ingresos y
              retenciones, constancias laborales, etc.
            </li>
            <li>Seguridad y control de acceso a los edificios.</li>
            <li>Informar a los clientes sobre el equipo de trabajo.</li>
            <li>
              Realizar los reportes ante las autoridades de inspección,
              vigilancia y control.
            </li>
            <li>
              Tramitar las respuestas a los requerimientos de entidades
              administrativas o judiciales.
            </li>
            <li>
              Todos los demás usos administrativos, comerciales y laborales que
              se establezcan en los contratos o que deriven de su actividad
              laboral.
            </li>
            <li>
              Análisis y evaluación de la formación académica del empleado.
            </li>
            <li>
              Conservación de la información por los términos establecidos en la
              Ley, especialmente el referente a la información de los libros y
              papeles del comerciante que deberá ser almacenada por un período
              de diez (10) años, según lo dispone el artículo 28 de la Ley 962
              de 2005.
            </li>
          </ul>
          <p>
            De llegarse a presentar otro tipo de finalidades en el tratamiento
            de datos personales se solicitará la autorización previa, expresa e
            informada del Titular.
          </p>
          <p>
            <strong>
              Finalidad del tratamiento de los datos personales de Proveedores
            </strong>
          </p>
          <p>
            La recolección y tratamiento de los datos personales de los
            proveedores se hará con el fin de contactar y contratar los
            productos o servicios que<strong> ProcessMática S.A.S. </strong>
            requiera para el normal funcionamiento de su operación
            <strong>,</strong>de igual forma tendrá las siguientes finalidades:
          </p>
          <ul>
            <li>
              Solicitar o realizar consultorías, auditorías, asesorías o
              servicios relacionados con los proveedores.
            </li>
            <li>
              Cumplir con los procesos internos de la empresa en materia de
              administración de proveedores y contratistas.
            </li>
            <li>
              Adelantar procesos al interior de la empresa, con fines de
              desarrollo operativo y/o de administración de sistemas.
            </li>
            <li>
              Realizar declaraciones tributarias o gestión de información
              tributaria y de recaudo.
            </li>
            <li>
              Mantener un registro de la información de proveedores.<br></br>
            </li>
            <li>
              Realizar reportes ante las autoridades de inspección, vigilancia y
              control y/o responder a los requerimientos de entidades
              administrativas.
            </li>
            <li>Realizar gestiones de proveedores y de cobros y pagos.</li>
            <li>
              Realizar el registro y control de entrada y salida de las
              instalaciones de<strong> ProcessMática S.A.S. </strong>
            </li>
            <li>
              Verificar la información aportada por los proveedores, para
              controlar y prevenir el fraude.
            </li>
            <li>
              Gestionar procedimientos administrativos para desarrollar
              cualquier otra actividad en cumplimiento de la relación entre
              proveedores y<strong> ProcessMática S.A.S. </strong>
            </li>
            <li>
              Conservación de la información por los términos establecidos en la
              Ley, especialmente el referente a la información de los libros y
              papeles del comerciante que deberá ser almacenada por un período
              de diez (10) años, según lo dispone el artículo 28 de la Ley 962
              de 2005.
            </li>
            <li>
              Todos los demás usos administrativos y comerciales que se
              establezcan en los contratos o que deriven de su actividad.
            </li>
          </ul>
          <p>
            Para otras finalidades o tratamiento se pedirá autorización previa,
            expresa e informada al Titular.
          </p>
          <p>
            <strong>
              Finalidad del tratamiento de los datos personales de Socios
            </strong>
          </p>
          <p>
            La recolección y tratamiento de los datos personales de los socios
            de<strong> ProcessMática S.A.S. </strong>tendrá carácter privado y
            se realizará con el fin de permitir el ejercicio de derechos y
            deberes relacionados con tal condición, así como para llevar a cabo
            las siguientes finalidades:
          </p>
          <ul>
            <li>
              Realizar el registro de gestión económica, contable, fiscal, y
              administrativa.
            </li>
            <li>
              Realizar la emisión de certificaciones relativas a la titularidad
              de las acciones, así como expedición de certificados de acciones y
              las demás necesarias para el desarrollo adecuado del objeto
              social, previa autorización de los mismos.
            </li>
            <li>
              Realizar Procesos al interior de la Institución, con fines de
              desarrollo operativo y/o de administración de sistemas.
            </li>
            <li>
              Realizar cualquier otra finalidad necesaria para el desarrollo de
              los derechos y deberes inherentes a su calidad de accionistas.
            </li>
            <li>
              Conservación de la información por los términos establecidos en la
              Ley, especialmente el referente a la información de los libros y
              papeles del comerciante que deberá ser almacenada por un período
              de diez (10) años, según lo dispone el artículo 28 de la Ley 962
              de 2005.
            </li>
          </ul>
          <p>
            Para otras finalidades o tratamiento se pedirá autorización previa,
            expresa e informada al Titular.
          </p>
          <p>
            <strong>VII. AUTORIZACIÓN Y CONSENTIMIENTO DEL TITULAR</strong>
          </p>
          <p>
            El consentimiento y autorización por parte del Titular de la
            información es un requisito constitucional y legal que deben cumplir
            las personas responsables del tratamiento de datos personales. El
            consentimiento debe cumplir con los siguientes presupuestos:
          </p>
          <p>
            <strong>Previo:</strong>La autorización la debe dar el Titular de
            información de manera previa a cualquier tipo de Tratamiento de
            datos personales.
          </p>
          <p>
            <strong>Expreso:</strong>La autorización debe otorgarse de forma
            inequívoca, clara y específica.
          </p>
          <p>
            <strong>Informado:</strong>El Titular debe comprender claramente
            para qué serán tratados sus datos personales y las finalidades que
            pueden derivarse del Tratamiento de los mismos.
          </p>
          <p>
            Todos los visitantes de las siguientes plataformas: (i)
            http://www.porcessmatica.com.co; (ii) Herramientas descargables
            elaboradas por ProcessMática S.A.S , deben registrarse y autorizar
            el tratamiento de los datos personales para poder hacer uso de los
            servicios ofrecidos. Por tanto, en cada uno de los sistemas se
            encuentra una casilla que dice “Política de privacidad y Tratamiento
            de Datos Personales” la cual debe ser leída y aceptada para poder
            continuar con el uso de los servicios de
            <strong> ProcessMática S.A.S. </strong>
          </p>
          <p>
            <strong>
              Obtención de Datos Recolectados Antes de la Vigencia del Decreto
              1377 de 2013
            </strong>
          </p>
          <p>
            Para la obtención o recolección de Datos Personales de los Titulares
            obtenidos antes de la vigencia del Decreto 1377 de 2013, se buscará
            obtener la autorización a través de mecanismos eficientes de
            comunicación. Se entiende por mecanismos eficientes de comunicación
            aquellos que la Compañía usa en el curso ordinario de su interacción
            con los Titulares como correos electrónicos, llamadas telefónicas
            entre otros.
          </p>
          <p>
            <strong>
              VIII. CANALES DE ACCESO Y MECANISMOS DISPUESTOS POR ProcessMática
              S.A.S.
            </strong>
          </p>
          <p>
            En desarrollo de la garantía constitucional de Habeas Data respecto
            de los derechos de acceso, actualización, rectificación y supresión
            por parte del Titular de los datos personales, sus causahabientes,
            representantes legales y/o apoderados,
            <strong> ProcessMática S.A.S. </strong>habilitará canales de acceso
            para los Titulares.
          </p>
          <p>
            Todas las comunicaciones, consultas, quejas y/o reclamos deberán ser
            dirigidas al Oficial de Protección de Bases de Datos Personales o al
            <strong>ÁREA DE COSTUMER CARE/SERVICIO AL CLIENTE</strong>
            de<strong> ProcessMática S.A.S. </strong>, por cualquiera de los
            siguientes medios:
          </p>
          <ul>
            <li>
              Atención electrónica: El Titular del dato deberá realizar su
              requerimiento a través del centro de ayuda disponible en la
              plataforma virtual.
            </li>
            <li>
              Atención escrita: El Titular del dato deberá realizar su
              requerimiento formal a la dirección: Calle 93 # 19-75 de Bogotá,
              Colombia.
            </li>
          </ul>
          <p>
            A través de los medios señalados, el Titular podrá eliminar todos
            los Datos Personales que tiene en ProcessMática S.A.S cuando quiera
            a través de sus distintas plataformas.
          </p>
          <p>
            <strong>
              IX. PROCEDIMIENTO LEGAL PARA CONSULTAS QUEJAS Y RECLAMOS
            </strong>
          </p>
          <p>
            Las consultas, quejas o reclamos podrán ser presentados a través de
            un documento con las características que se describen en cada uno de
            los supuestos o mediante el formulario de consultas quejas y
            reclamos que podrán consultar en
            <a href="http://www.processmatica.com.co/">
              www.processmatica.com.co
            </a>
            en la opción Soporte.
          </p>
          <p>
            <strong>Consultas</strong>
          </p>
          <p>
            Tratándose del derecho de petición en calidad de información y/o
            consultas,<strong> ProcessMática S.A.S. </strong>dará respuesta en
            un término máximo de diez (10) días hábiles contados a partir del
            día siguiente a la fecha de recepción de la petición o consulta.
          </p>
          <p>
            Cuando no fuere posible atender la consulta dentro de dicho término
            se informará al interesado, indicando los motivos de la demora y
            señalando la fecha en que esta se atenderá, la cual en ningún caso
            podrá superar cinco (5) días hábiles siguientes al vencimiento del
            primer término. El documento de consulta deberá contener lo
            siguiente:
          </p>
          <ol>
            <li>
              Área de<strong> ProcessMática S.A.S. </strong>a la cual va
              dirigida (Costumer care/Servicio al Cliente)
            </li>
            <li>El nombre y número de identificación del Titular.</li>
            <li>Copia documento de identidad del titular</li>
          </ol>
          <p>
            En caso de que el causahabiente del Titular solicite la consulta,
            deberá adjuntar a la consulta:
          </p>
          <ol>
            <li>
              Área de<strong> ProcessMática S.A.S. </strong>a la cual va
              dirigida (Costumer care/Servicio al cliente)
            </li>
            <li>El nombre y número de identificación del Titular.</li>
            <li>Copia Documento de identidad del Causahabiente.</li>
            <li>Copia Registro civil de defunción del Titular.</li>
            <li>Documento que acredite la calidad en que obra.</li>
            <li>Copia documento de identidad del Titular.</li>
            <li>Descripción completa de la consulta.</li>
            <li>Dirección y datos de contacto del consultante.</li>
          </ol>
          <ul>
            <li>
              Si se trata del representante legal y/o apoderado del Titular,
              deberá presentar:
            </li>
          </ul>
          <ol>
            <li>
              Área de<strong> ProcessMática S.A.S. </strong>a la cual va
              dirigida (Costumer care/Servicio al Cliente)
            </li>
            <li>El nombre y número de identificación del Titular.</li>
            <li>Copia Documento de identidad del representante legal</li>
            <li>
              Documento que acredite la calidad en la que obra (poder,
              certificación).
            </li>
            <li>Copia documento de identidad del Titular.</li>
            <li>
              En caso de que el apoderado sea menor de edad, deberá presentar la
              tarjeta de identidad y/o el registro civil y el documento que
              acredite la calidad de representante o custodio del menor.
            </li>
            <li>Descripción completa de la consulta.</li>
            <li>Dirección y datos de contacto del consultante.</li>
          </ol>
          <p>
            <strong>Quejas y/o reclamos</strong>
          </p>
          <p>
            Cuando el Titular considere que su información debe ser objeto de
            corrección, actualización o supresión o cuando advierta un presunto
            incumplimiento de cualquiera de sus derechos, el término máximo para
            atender la queja o reclamo será de quince (15) días hábiles contados
            a partir del día siguiente a la fecha de recepción del documento.
          </p>
          <p>
            Cuando no fuere posible atender el reclamo dentro de dicho término,
            se informará al interesado los motivos de la demora y la fecha en
            que será atendido, la cual en ningún caso podrá superar los ocho (8)
            días hábiles siguientes al vencimiento del primer término. Si el
            reclamo resulta incompleto, se requerirá al interesado dentro de los
            cinco (5) días siguientes a la recepción de la queja y/o reclamo
            para que subsane las fallas. Transcurridos dos (2) meses desde la
            fecha del requerimiento sin que el solicitante presente la
            información requerida, se entenderá desistida la queja o reclamo. El
            documento de queja o reclamo deberá contener:
          </p>
          <ol>
            <li>
              Área de<strong> ProcessMática S.A.S. </strong>a la cual va
              dirigida (Constumer Care/Servicio al cliente)
            </li>
            <li>El nombre y número de identificación del Titular.</li>
            <li>Copia documento de identidad del titular</li>
          </ol>
          <ul>
            <li>
              En caso de que el causahabiente del Titular presente la queja y/o
              reclamo, deberá adjuntar:
            </li>
          </ul>
          <ol>
            <li>
              Área de<strong> ProcessMática S.A.S. </strong>a la cual va
              dirigida (Constumer Care/Servicio al Cliente)
            </li>
            <li>El nombre y número de identificación del Titular.</li>
            <li>Copia documento de identidad del titular</li>
            <li>Copia Documento de identidad del Causahabiente </li>
            <li>Copia Registro civil de defunción del Titular.</li>
            <li>Descripción completa de la consulta.</li>
            <li>Dirección y datos de contacto del consultante.</li>
            <li>Documento que acredite la calidad en que obra.</li>
          </ol>
          <ul>
            <li>Si se trata de un representante legal y/o apoderado:</li>
          </ul>
          <ol>
            <li>
              Área de<strong> ProcessMática S.A.S. </strong>a la cual va
              dirigida (Constumer Care)
            </li>
            <li>El nombre y número de identificación del Titular.</li>
            <li>Copia documento de identidad del titular</li>
            <li>Copia Documento de identidad del representante legal</li>
            <li>
              Documento que acredite la calidad en la que obra (poder,
              certificación).
            </li>
            <li>
              En caso de que el apoderado sea menor de edad, deberá presentar la
              tarjeta de identidad y/o el registro civil y el documento que
              acredite la calidad de representante o custodio del menor.
            </li>
            <li>
              Descripción de los hechos que dan lugar al reclamo y/o queja.
            </li>
            <li>El objeto perseguido.</li>
            <li>Dirección y datos de contacto del reclamante.</li>
          </ol>
          <p>
            <strong>X. TRATAMIENTO DE DATOS PERSONALES SENSIBLES</strong>
          </p>
          <p>
            <strong> ProcessMática S.A.S. </strong>no recolectará, almacenará o
            tratará datos sensibles, a menos que sea estrictamente necesario. De
            presentarse dicha situación, no realizará ningún tratamiento sin la
            debida autorización previa, informada y expresa del Titular de la
            información, salvo en los casos que por ley no sea requerido el
            otorgamiento de la misma y se presente alguna de las siguientes
            excepciones:
          </p>
          <ul>
            <li>
              Cuando el tratamiento sea necesario para salvaguardar el interés
              vital del Titular y este se encuentre física o jurídicamente
              incapacitado.
            </li>
            <li>
              Cuando el tratamiento sea efectuado en el curso de las actividades
              legítimas y con las debidas garantías por parte de una fundación,
              ONG, asociación o cualquier otro organismo sin ánimo de lucro,
              cuya finalidad sea política, filosófica, religiosa o sindical,
              siempre que se refieran exclusivamente a sus miembros o a las
              personas que mantengan contactos regulares por razón de su
              finalidad, en cuyos casos, los datos no se podrán suministrar a
              terceros sin la autorización del Titular.
            </li>
            <li>
              Cuando el tratamiento se refiera a datos que sean necesarios para
              el reconocimiento, ejercicio o defensa de un derecho en un proceso
              judicial.
            </li>
            <li>
              Cuando el tratamiento tenga una finalidad histórica, estadística o
              científica. En este evento deberán adoptarse las medidas
              conducentes a la supresión de identidad de los Titulares.
            </li>
            <li>
              Cuando se realice en cumplimiento de una orden pública o
              administrativa en ejercicio de sus funciones legales o por orden
              judicial.
            </li>
          </ul>
          <p>
            Las respuestas a las preguntas sobre datos sensibles son
            facultativas, por lo tanto, no serán obligatorias.En todo caso,
            <strong> ProcessMática S.A.S. </strong>observará estrictamente las
            limitaciones legales al Tratamiento de Datos Sensibles.
            <strong> ProcessMática S.A.S.,</strong>no condicionará, en ningún
            caso, ninguna actividad a la entrega de Datos Sensibles. Los Datos
            Sensibles serán tratados con la mayor diligencia posible y con los
            estándares de seguridad más altos. El acceso limitado a los Datos
            Sensibles será un principio rector para salvaguardar la privacidad
            de estos y, por lo tanto, sólo el personal autorizado podrá tener
            acceso a ese tipo de información
          </p>
          <p>
            No podrá realizarse el tratamiento de datos sensibles para fines
            distintos de los autorizados expresamente por el Titular
          </p>
          <p>
            <strong>
              XI. TRATAMIENTO DE DATOS PERSONALES DE NIÑOS, NIÑAS Y/O
              ADOLESCENTES
            </strong>
          </p>
          <p>
            <strong> ProcessMática S.A.S. </strong>procurará no realizar ningún
            tratamiento sobre datos de niños, niñas y adolescentes menores de 18
            años. El uso de la plataforma solo es para mayores de edad. Sin
            embargo, en caso de que los menores de 18 años con capacidad
            jurídica quieran usar el servicio deberán contar la autorización
            expresa por parte de su representante legal.
          </p>
          <p>
            De presentarse el tratamiento de datos de menores de edad,
            <strong> ProcessMática S.A.S. </strong>garantizará, como Responsable
            del Tratamiento, que dichos datos personales serán tratados
            adecuadamente, aplicando los principios y obligaciones establecidos
            en la Ley 1581 de 2012 y demás disposiciones sobre protección de
            bases de datos personales vigentes.
          </p>
          <p>
            El tratamiento de datos personales de niños, niñas y adolescentes
            tendrá un tratamiento especial y unas medidas rigurosas para su
            protección. Aquellos datos que no sean de naturaleza pública
            cumplirán con los siguientes parámetros y requisitos.
          </p>
          <ul>
            <li>
              Responderá y respetará el interés superior de los niños, niñas y
              adolescentes.
            </li>
            <li>
              Se asegurará el respeto de los derechos fundamentales de los
              niños, niñas y adolescentes.
            </li>
            <li>
              Se valorará la opinión del menor cuando éste cuente con la
              capacidad y autonomía debidas para entender el asunto.
            </li>
          </ul>
          <p>
            En todo caso, corresponderá a los representantes legales de los
            niños, niñas y adolescentes otorgar la autorización para proceder
            con el Tratamiento de los datos personales de los menores, si no se
            cuenta con la autorización del representante no se hará tratamiento
            de los mismos y no se le podrá prestar el servicio al menor.
          </p>
          <p>
            <strong>
              XII. TRANSMISIÓN Y/O TRANSFERENCIA NACIONAL O INTERNACIONAL
            </strong>
          </p>
          <p>
            <strong> ProcessMática S.A.S. </strong> no podrá compartir la
            información de los datos personales con terceros.
          </p>

          <p>
            <strong>XIII. VIGENCIA</strong>
          </p>
          <p>
            Los Datos Personales que sean almacenados o utilizados permanecerán
            en las bases de datos de
            <strong> ProcessMática S.A.S. </strong>durante el tiempo que sea
            necesario para cumplir con las finalidades expuestas en este manual
            o para que la Empresa pueda cumplir con sus deberes legales.
          </p>
          <p>
            Sin embargo, la información será revisada cada año para verificar la
            veracidad del dato y finalidad de seguir con su tratamiento.
            <br></br>De ser necesario<strong> ProcessMática S.A.S. </strong>
            se reserva el derecho a modificar la presente Política de forma
            unilateral.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PoliticaDatos;
