import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AuthProvider } from '../src/context/AuthContext';
import { Provider } from 'react-redux';
import store from './redux/store';
import Header from './components/Header/Header';
import HomeView from './components/HomeView/HomeView';
import AuthView from './components/Auth/AuthView';
import PendingVerification from './components/Auth/PendingVerification';
import ConfirmRegisterMail from './components/Auth/ConfirmRegisterMail';
import ConfirmResetPasswordMail from './components/Auth/ConfirmResetPasswordMail';
import ResetPasswordMail from './components/Auth/ResetPasswordMail';
import AdminView from './components/AdminView/AdminView';
import UsersView from './components/UsersView/UserView';
import ProductServicesView from './components/ProductsServices/ProductServicesView'
import GDBAudit from './components/ProductsServices/GDBAudit';
import GISConsultancy from './components/ProductsServices/GISConsultancy';
import EnvConsultancy from './components/ProductsServices/EnvConsultancy';
import AboutUs from './components/AboutUs/AboutUsView'
import PricingView from './components/Pricing/PricingView'
import DownloadsView from './components/Download/DownloadsView';
import MainProfileView from './components/ProfileView/MainProfileView'
import SupportView from './components/Support/SupportView';
import PoliticaDatos from './components/Support/PoliticaDatos';
import TerminosUso from './components/Support/TerminosUso';
import ContactUs from './components/Support/ContactUs';

import Footer from './components/Footer/Footer';
import './App.css'
import './styles/global.css';
Amplify.configure(awsconfig);

function App() {
    useEffect(() => {
        document.title = "ProcessMática";
    }, []);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }, []);
    return (
        <Provider store={store}>
            <AuthProvider>
                <Router>
                    <div className="app-container">
                        <Header />
                        <div className="main-content">
                            <Routes>
                                <Route path="/" element={<HomeView />} />
                                <Route path="/product-services/*" element={<ProductServicesView />} />
                                <Route path="/product-services/GDBAudit" element={<GDBAudit />} />
                                <Route path="/product-services/GISConsultancy" element={<GISConsultancy />} />
                                <Route path="/product-services/EnvConsultancy" element={<EnvConsultancy />} />
                                <Route path="/about-us" element={<AboutUs />}  />
                                <Route path="/pricing" element={<PricingView />} />
                                <Route path="/downloads" element={<DownloadsView />} />
                                <Route path="/auth/*" element={<AuthView />} />
                                <Route path="/auth/pending-verification" element={<PendingVerification />} />
                                <Route path="/auth/email-register-verification" element={<ConfirmRegisterMail />} />
                                <Route path="/auth/email-reset-password-verification" element={<ConfirmResetPasswordMail />} />
                                <Route path="/auth/reset-password" element={<ResetPasswordMail />} />
                                <Route path="/profile/*" element={<MainProfileView />} />
                                <Route path="/adminview/*" element={<AdminView />} />
                                <Route path="/userview/*" element={<UsersView />} />
                                <Route path="/support/*" element={<SupportView />} />
                                <Route path="/support/politica-tratamiento-datos" element={<PoliticaDatos />} />
                                <Route path="/support/terminos-uso-servicio" element={<TerminosUso />} />
                                <Route path="/support/contactUs" element={<ContactUs />} />

                                {/* Add other routes as needed */}
                            </Routes>
                        </div>
                        <div className="footer-content">
                            <Footer />
                        </div>
                        
                    </div>
                </Router>
            </AuthProvider>
        </Provider>
    );
}

export default App;


