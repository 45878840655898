//MainProfileView.jsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import AdminView from '../AdminView/AdminView';
import UserView from '../UsersView/UserView';

function MainProfileView() {
    const { currentUser, isAuth, refreshAccessToken } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isAuth) {
            //console.log('currentUser', currentUser)
            // User data has been loaded
            setIsLoading(false);
        }else{
            refreshAccessToken();
        }
    }, [currentUser, isAuth, refreshAccessToken]);

    if (isLoading) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    return (
        <div className="profile-view-container">
            <div>
                {currentUser && currentUser.role === 'admin' ? (
                    <AdminView />
                ) : (
                    <UserView />
                )}
            </div>
        </div>
    );
}

export default MainProfileView;
