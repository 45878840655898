// redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null, // The current user's non-sensitive data
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to set the current user's information
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    // Action to clear the current user's information on logout
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
    // You might have additional actions related to authentication state but not token management
  },
  // You can also have extraReducers here for handling async actions
});

// Export actions
export const { setCurrentUser, clearCurrentUser } = authSlice.actions;

// Default export the reducer
export default authSlice.reducer;

