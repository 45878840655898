//ProductServicesView.jsx
import React from 'react';
import { useEffect } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import './ProductServicesView.css';
import ComboImageText from '../Customs/ComboImageText/ComboImageText';
import ProductInfoCard from '../Customs/ProductInfoCard/ProductInfoCard';
//import ScrollDownSign from '../Customs/ScrollDownIndicator/ScrollDownInicator';
import productServicesBG from '../../assets/images/products-services-bg.png';
import gdbReview from '../../assets/images/gdb-calidad.png';
import GISConsultancy from '../../assets/images/GIS-Environment-Consulting.png';
import EnvironmentalConsultancy from '../../assets/images/relaciones-ambiental-003.png';
import CommitmentImg from '../../assets/images/relaciones-ambiental-005.png';
import GDBAuditIcon from '../../assets/images/GDB-Audit-icon.png';
import GISConsultanciImg from '../../assets/images/GIS-consultancy-image.png';
import EnvConsultancy from '../../assets/images/EnvConsultancy-image.png';
import InnovationImg from '../../assets/images/innovation-image.png';

function useAnchorScroll() {
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const anchorElement = document.querySelector(location.hash);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]); // Notice the dependency array now includes location
}

function ProductServicesView() {
    
    useAnchorScroll();

    return (
        <div className='secondary-pages-view'>
            <div className="secondary-pages-container" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${productServicesBG})` }}>
                <div className='section-header'>
                    NUESTROS PRODUCTOS Y SERVICIOS
                </div>
                <div className='product-selection-wrapper'>
                    <div className='products-selector'>
                        <Link className='products-selector-link' to="#GDBAudit">
                            <div className='product-class-container'>
                                <div className='product-class-image-div' style={{ backgroundImage: `url(${gdbReview})` }}>
                                    {/* div for image as background. */}
                                </div>
                                <div className='product-class-title'>
                                    Auditoría de Geodatabases
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='products-selector'>
                        <Link className='products-selector-link' to="#GISConsultancy">
                            <div className='product-class-container'>
                                <div className='product-class-image-div' style={{ backgroundImage: `url(${GISConsultancy})` }}>
                                    {/* div for image as background. */}
                                </div>
                                <div className='product-class-title'>
                                    Consultoría SIG
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='products-selector'>
                        <Link className='products-selector-link' to='#EnvConsultancy'>
                            <div className='product-class-container'>
                                <div className='product-class-image-div' style={{ backgroundImage: `url(${EnvironmentalConsultancy})` }}>
                                    {/* div for image as background. */}
                                </div>
                                <div className='product-class-title'>
                                    Consultoría Ambiental
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <ScrollDownSign 
                    arrowColor="#ff0000" 
                    containerStyle={{marginBottom: "20px"}}
                /> */}
                <ComboImageText 
                    ComboImageTextWrapperClassName="commitment-combo-wrapper"
                    ImageLeft={true}
                    ImageUrl= {CommitmentImg}
                    ImageWidth= '500px'
                    ImageHeight= '200px'
                    ImageClassName="commitment-img"
                    ComboImageTextTextContentWrapper='commitment-text-content-wrapper'
                    TextContenttitle="Soluciones pensadas en sus necesidades"
                    TextContenttitleClassName="commitment-combo-title"
                    TextContentBody= {<>
                            <p>Nos interesamos en las <strong>verdaderas necesidades</strong> de nuestros clientes y sus proyectos.</p>
                            <p>Todas nuestras soluciones se construyen en torno a la generación de valor al simplificar los procesos del negocio.</p>
                        </>}
                    TextContentBodyClassName="commitment-combo-bodytext"
                />
                <ProductInfoCard 
                    id='GDBAudit'
                    ProductInfoCardWrapperClassName="GDBAudit-info-card-wrapper"
                    TitleText="GDB Audit"
                    TitleClassName="GDBAudit-product-title"
                    DescriptionText={<>
                        <p style={{fontWeight:'600'}}>Conjunto de herramienas de <strong>ArcGIS Desktop</strong> enfocadas en la revisión de las GDBs para cumplir con el Modelo de Almacenamiento Geográfico (MAG) de la ANLA.</p>
                        <p>Los resultados se presentan en hojas de cálculo (.xls) para facilmente interpretar el cumplimiento del MAG en los siguientes temas: </p>
                        <p>Revisión de cumplimiento de estructura, revisión de calidad de atributos, revisión de calidad topológica, revisión de relaciones espaciales, revisión de relaciones entre tablas, generación de información para metadatos, entre otras.</p>
                        <div style={{display:'flex', flexDirection:'column', fontSize: '1rem'}}>
                            <Link to= '/product-services/GDBAudit'>Descripción detallada de las herramientas</Link>
                            <Link to= '/downloads/#GDBAudit'>Descarga el Toolbox para ArcGIS Desktop</Link>
                        </div>
                    </>}
                    DescriptionClassName="GDBAudit-product-description"
                    IconUrl={GDBAuditIcon}
                    IcontContainerClassName = "GDBAudit-product-icon-container"
                    IconWidth='200px'
                    IconHeight='200px'
                    IconClassName="GDBAudit-product-icon"
                />
                <ProductInfoCard 
                    id='GISConsultancy'
                    ProductInfoCardWrapperClassName="GISConsultancy-info-card-wrapper"
                    TitleText="Consultoría SIG"
                    TitleClassName="GISConsultancy-product-title"
                    DescriptionText={<>
                        <p style={{fontWeight:'600'}}>Somos integradores de soluciones de consultoría de <strong>SIG para estudios ambientales</strong>.</p>
                        <p>Nuestra red de aliados está a su disposición para encontrar la solución a sus necesidades de SIG en el desarrollo de estudios ambientales.</p>
                        <p>Nuestros aliados tienen ámplia experiencia en elaboración de estudios ambientales, conectividad ecológica, compensación forestal, diligenciamiento de información para GDB ANLA, y mucho más.</p>
                        <div style={{display:'flex', flexDirection:'column', fontSize: '1rem'}}>
                            <Link to= '/product-services/GISConsultancy'>Descubre nuestos servicios de consultoría SIG</Link>
                            <Link to= '/support/contactUs'>Contáctenos</Link>
                        </div>
                    </>}
                    DescriptionClassName="GISConsultancy-product-description"
                    IconUrl={GISConsultanciImg}
                    IcontContainerClassName = "GISConsultancy-product-icon-container"
                    IconWidth='200px'
                    IconHeight='200px'
                    IconClassName="GISConsultancy-product-icon"
                />
                <ProductInfoCard 
                    id='EnvConsultancy'
                    ProductInfoCardWrapperClassName="EnvConsultancy-info-card-wrapper"
                    TitleText="Consultoría Ambiental"
                    TitleClassName="EnvConsultancy-product-title"
                    DescriptionText={<>
                        <p style={{fontWeight:'600'}}>Somos integradores de soluciones de  <strong>consultoría para estudios ambientales</strong>.</p>
                        <p>Nuestro equipo de trabajo está conformadó por profesionales con amplia experiencia en consultoría ambiental, en campos de especialización como la biología, ingeniería forestal, economía ambiental, entre otros.</p>
                        <p>Además, contamos con una red de aliados que nos garantiza la participación en proyectos ambientales multidisciplinares.</p>
                        <div style={{display:'flex', flexDirection:'column', fontSize: '1rem'}}>
                            <Link to= '/product-services/EnvConsultancy'>Descubre nuestos servicios de consultoría ambiental</Link>
                            <Link to= '/support/contactUs'>Contáctenos</Link>
                        </div>
                    </>}
                    DescriptionClassName="EnvConsultancy-product-description"
                    IconUrl={EnvConsultancy}
                    IcontContainerClassName = "EnvConsultancy-product-icon-container"
                    IconWidth='200px'
                    IconHeight='200px'
                    IconClassName="EnvConsultancy-product-icon"
                />
            </div>
            <ComboImageText 
                    ComboImageTextWrapperClassName="innovation-combo-wrapper"
                    ImageLeft={false}
                    ImageUrl= {InnovationImg}
                    ImageWidth= '500px'
                    ImageHeight= '200px'
                    ImageClassName="innovation-img"
                    ComboImageTextTextContentWrapper='innovation-text-content-wrapper'
                    TextContenttitle="Desarrollo continuo de servicios y productos."
                    TextContenttitleClassName="innovation-combo-title"
                    TextContentBody= {<>
                            <p>A través de metodologías ágiles desarrollamos nuevos productos y servicios.</p>
                            <p>La innovación hace parte de nuestro ADN y pronto estaremos ofreciendo más herramientas para gestionar los procesos de la ingeniería ambiental y SIG.</p>
                        </>}
                    TextContentBodyClassName="innovation-combo-bodytext"
                />
            <div className='spacer' style={{marginTop: '40px'}}></div>
        </div>
    );
}

export default ProductServicesView;