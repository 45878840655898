import React from 'react'; // Add useContext import
import { useNavigate } from 'react-router-dom';
import './RegisterForm.css';

function EmailVerification() {
    const navigate=useNavigate();
    return (
        <div className='secondary-pages-view'>
            <div style={{
                'width': '100%', 
                'display':'flex',
                'flexDirection':'column', 
                'justifyContent':'center',
                'alignItems':'center',
                'marginTop':'100px'
                }}>
                <div className='pending-container'
                    style={{
                    'width': '600px', 
                    'boxShadow':'0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}>
                    <h2 style={{
                        'marginLeft': '20px'
                        }}>Hemos enviado un correo de verificación a la dirección de email registrada.</h2>
                    <p style={{ 
                        'width':'90%', 
                        'marginLeft': '20px',
                        'marginTop': '20px'}}>Si ya verificaste el correo has click en el siguiente botón para volver al login.</p>
                    <button 
                        style={{ 'width':'90%'}} 
                        className='register-verification' 
                        type="submit" 
                        onClick={() => navigate('/auth')}>Entrar a ProcessMática</button>
                </div>
            </div>
            
            
        </div>
    );
}

export default EmailVerification;
