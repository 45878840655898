//TerminosUso.jsx
import React from "react";
import "./TerminosUso.css";

function TerminosUso() {
  return (
    <div className="secondary-pages-view">
      <div className="secondary-pages-container">
        <div className="section-header">
          Términos de uso del servicio
        </div>
        <div className="secondary-page-main-body">
          <h2 class="entry-title">ProcessMática S.A.S </h2>
          
        </div>
      </div>
    </div>
  );
}

export default TerminosUso;
