// GDBAudit.jsx
import React from 'react';
import './GDBAudit.css';

function GDBAudit() {
    return (
        <div className='secondary-pages-view'>
            <div className="secondary-pages-container">
                <div className="section-header">
                    HERRAMIENTAS DE REVISIÓN DE GDB ANLA
                </div>
                <div className="GDBAudit-content">

                    <div className="GDBAudit-section-title">GDB Audit</div>

                    <div className="GDBAudit-description">
                        <div className="GDBAudit-paragraph">
                            Conjunto de herramientas desarrolladas para revisar-validar la GDB temática de los estudios que serán presentados ante las autoridades ambientales. Las herramientas se han almacenados en una Toolbox para ArcGIS, y por esta razón es indispensable tener instalado y licenciado el software ArcGIS for Desktop versión 10.6 o superior. Al distribuir las herramientas de esta forma, éstas pueden ser utilizadas sin necesidad de instaladores.
                        </div>
                        <div className="GDBAudit-paragraph">
                            El cobro del servicio se basa en un modelo SaaS (Software como Servicio), en el cual no es necesaria la adquisición de las licencias de uso de las herramientas, sino que se realizan pagos por utilización.
                        </div>
                        <div className="GDBAudit-paragraph">
                            En total, se han desarrollado 15 herramientas, las cuales cubren las siguientes temáticas:
                        </div>
                    </div>

                    {/* ... add more content following the above structure ... */}

                    <div className="section-content">
                        <div className="section-title">
                            <h2>ASPECTOS GENERALES DE LA GDB</h2>
                        </div>
                        <div className="section-item">
                            <p><strong>1. Revisión general de la estructura de GDB (R_INF_01_General):</strong> Esta lista las modificaciones a la estructura de la GDB. Revisa elementos eliminados, elementos nuevos, elementos sin registros, reporte completo de dominios (dominios nuevos, eliminados, modificaciones en dominios, registros que utilizan los dominios nuevos, dominios nuevos no utilizados, valores por fuera del domino del campo…)</p>
                        </div>
                        <div className="section-item">
                            <p><strong>2. Revisión de sistemas de coordenadas utilizados (R_INF_02_SistCoordenadas):</strong> Consiste en la revisión de la información geográfica de la GDB y carpetas de información ráster.</p>
                        </div>
                        <div className="section-item">
                            <p><strong>3. Revisión de los datos de identificación del proyecto (R_INF_03_DatosProyecto):</strong> Consiste en el cotejo de los datos de identificación del proyecto (‘EXPEDIENTE’, ‘PROYECTO’, ‘OPERADOR’,etc) con todas las tablas de GDB.</p>
                        </div>
                    </div>

                    <div className="section-content">
                        <div className="section-title">
                            <h2>ASPECTOS RELACIONADOS CON LOS ELEMENTOS GEOMÉTRICOS</h2>
                        </div>
                        <div className="section-item">
                            <p><strong>4. Revisión topológica (R_INF_04_Topologias):</strong> revisión de topología sobre todas los Feature Classes de la GDB. Se revisan las reglas topológicas:</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Geometría Feature Class</th>
                                        <th>Regla topológica</th>
                                        <th>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Polígono</td>
                                        <td>Must Not Overlap</td>
                                        <td>Requiere que el interior de los polígonos no se superponga</td>
                                    </tr>
                                    <tr>
                                        <td>Polígono</td>
                                        <td>Must Not Have Gaps</td>
                                        <td>No debe haber espacios al interior de un polígono simple o entre polígonos adyacentes</td>
                                    </tr>
                                    <tr>
                                        <td>Línea</td>
                                        <td>Must Not Overlap</td>
                                        <td>Requiere que las líneas no se superpongan con otras líneas de la misma clase</td>
                                    </tr>
                                    <tr>
                                        <td>Línea</td>
                                        <td>Must Not Intersect</td>
                                        <td>Requiere que las entidades de línea de la misma clase no se crucen entre sí</td>
                                    </tr>
                                    <tr>
                                        <td>Línea</td>
                                        <td>Must Not Self-Intersect</td>
                                        <td>Requiere que una línea no se cruce consigo misma</td>
                                    </tr>
                                    <tr>
                                        <td>Línea</td>
                                        <td>Must Not Self-Overlap</td>
                                        <td>Requiere que una línea no se superponga consigo misma</td>
                                    </tr>
                                    <tr>
                                        <td>Punto</td>
                                        <td>Must Be Disjoint</td>
                                        <td>Requiere que los puntos dentro de una misma clase estén separados espacialmente entre sí (no se pueden superponer)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="section-item">
                            <p><strong>5. Revisión de elementos multiparte (R_INF_05_Multipartes):</strong> Se hace un listado de todos los elementos de los Feature Classes de la GDB que son multiparte.</p>
                        </div>
                        <div className="section-item">
                            <p><strong>6. Revisión de los datos geométricos de los elementos (R_INF_06_DatosGeometrias):</strong> Consiste en la comparación de los datos geométricos diligenciados en los campos de los Feature Classes de la GDB, frente a los calculados directamente del objeto geométrico. Aplica para elementos tipo polígonos, líneas y puntos. (Ej, ‘AREA_Ha’, ‘COORD_ESTE’, ‘Longitud_m).</p>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="section-title">
                            <h2>CALIDAD DE LA INFORMACIÓN ATRIBUTIVA</h2>
                        </div>
                        <div className="section-item">
                            <p><strong>7. Revisión de campos obligatorios (R_INF_07_Obligatorios):</strong> generación de un listado de todos los campos obligatorios no diligenciados o parcialmente diligenciados.</p>
                        </div>
                        <div className="section-item">
                            <p><strong>8. Calidad general de los atributos (R_INF_08_QDatos):</strong> Consiste en la revisión de reglas de calidad general de los datos, como no empezar ni terminar con espacios, no diligenciar registros con textos vacíos, no diligenciar registros con la palabra ‘&lt;Null&gt;’, etc. Como producto de la revisión, se genera un listado de los errores encontrados.</p>
                        </div>
                    </div>

                    <div className="section-content">
                        <div className="section-title">
                            <h2>RELACIONES SEGÚN EL MODELO DE DATOS GEOGRÁFICOS (MAG)</h2>
                        </div>
                        <div className="section-item">
                            <p><strong>9. Revisión de campos identificadores (R_INF_09_QCamposIDs):</strong> revisión de los campos identificadores de los registros de las capas de la GDB que se utilizarán en relaciones Tabla-Tabla o relaciones espaciales. Se tienen en cuenta las condiciones particulares de cada tabla, por ejemplo, identificadores repetidos o nulos, cuando la definición en el diccionario de datos no lo permite.</p>
                        </div>
                        <div className="section-item">
                            <p><strong>10. Revisión de relaciones Tabla-Tabla (R_INF_10_Relaciones):</strong> Consiste en la revisión de las relaciones entre tablas y Feature Classes de la GDB, según las definiciones del diccionario de datos. En total, se revisan más de 130 relaciones entre tablas.</p>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="section-title">
                            <h2>RELACIONES ESPACIALES</h2>
                        </div>
                        <div className="section-item">
                            <p><strong>11. Revisión de relaciones espaciales con las capas de límites político-administrativos (R_INF_11_PoliticoAdmin):</strong> Consiste en la revisión espacial de la información de Departamento, Municipio y Vereda de los Feature Classes de la GDB. Esta revisión se basa en la información diligenciada en las capas ‘Departamento’, ‘Municipio’ y ‘UnidadTerritorial’ de la GDB.</p>
                        </div>
                        <div className="section-item">
                            <p><strong>12. Revisión de relaciones espaciales temáticas (R_INF_12_RelacionesEspaciales):</strong> Consiste en la revisión de las relaciones espaciales entre las diferentes capas de la GDB según se ha especificado en el modelo de datos.</p>
                        </div>
                    </div>

                    <div className="section-content">
                        <div className="section-title">
                            <h2>REVISIÓN DE CUBRIMIENTO ESPACIAL ENTRE CAPAS DE GEOGRÁFICAS</h2>
                        </div>
                        <div className="section-item">
                            <p><strong>13. Revisión de cubrimiento espacial por áreas de influencias (R_INF_13_InfraAIs):</strong> Consiste en la revisión del cubrimiento de los elementos del proyecto de infraestructura y demanda de recursos por las áreas de influencia del proyecto. Permite identificar, por ejemplo, si un vertimiento está por fuera del área de influencia del componente abiótico del proyecto.</p>
                        </div>
                        <div className="section-item">
                            <p><strong>14. Revisión de cubrimiento espacial de elementos de la GDB (R_INF_14_InfraAreas):</strong> Consiste en la revisión del cubrimiento espacial de los elementos de infraestructura y demanda de recursos del proyecto por otras capas de información, diferentes a las áreas de influencia, como lo son las capas de Predios, Departamento, Municipio, etc.</p>
                        </div>
                    </div>

                    <div className="section-content">
                        <div className="section-title">
                            <h2>INFORMACIÓN PARA METADATOS</h2>
                        </div>
                        <div className="section-item">
                            <p><strong>15. Generación de información para Metadatos (R_INF_15_InformacionMetadatos):</strong> Generación del listado de capas de información geográficas de la GDB y carpeta de ráster para la elaboración de la plantilla de metadatos.</p>
                        </div>
                    </div>
                    <div className="GDBAudit-section-title">FORMATOS DE PRESENTACIÓN DE LOS RESULTADOS</div>
                    
                    <div className="GDBAudit-description">
                        <div className="GDBAudit-paragraph">
                            Los resultados de las herramientas de revisión se presentan como una hoja de cálculo con el listado detallado de los hallazgos, identificando claramente los elementos de la GDB y la dando la explicación de la no conformidad. Este formato enfocado a profesionales SIG con conocimientos suficientes del Modelo de Almacenamiento Geográfico.
                        </div>
                    </div>
                    
                    <div className="GDBAudit-section-title">¿CUÁNTO VALE EL SERVICIO DE REVISIÓN?</div>
                    
                    <div className="GDBAudit-description">
                        <div className="GDBAudit-paragraph">
                            El valor del servicio de revisión de GDBs se calcula utilizando la herramienta GDB_Audit_Score (descarga por separado). Por medio de esta herramienta se obtiene un puntaje general y estandarizado (Score) que indica el esfuerzo requerido para ejecutar cada una de las herramientas. El Score es calculado teniendo en cuenta el número de Feature Classes y tablas en la GDB, así como el número de registros por cada uno de estos elementos.
                        </div>
                        <div className="GDBAudit-paragraph">
                            Una vez calculado el Score de la GDB, se obtiene el número de créditos de la utilización, según nuestra información de precios por servicio publicada en nuestro sitio web. (Ir al listado de precios).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GDBAudit;

