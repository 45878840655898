import React from 'react';
import './ClientsInfo.css';

function ClientsInfo() {
    return (
        <div className='secondary-pages-view'>
            <div className="clients-info-container">
                <h2>Our Clients</h2>
                <p>Logos, testimonials, or any other relevant content...</p>
                {/* Additional content about the clients */}
            </div>
        </div>
        
    );
}

export default ClientsInfo;
